import React, { useContext, useEffect, useMemo, useState } from 'react';
import { OverlayTrigger, Tooltip, Tab, Nav, Col, Row, Button } from 'react-bootstrap';
import DommusToggle from "../../../components/DommusToggle";
import WhatsappComponent from "./WhatsappComponent";
import SmsComponent from "./SmsComponent";
import EmailComponent from "./EmailComponent";
import VoipComponent from "./VoipComponent";
import "./style.scss";
import { WebSocketContext } from "../../../contexts/WebSocketContext";
import { AtendimentoContext } from "../../../contexts/AtendimentoContext";
import caAPI from "../../../services/caAPI";
import Swal from "sweetalert2";
import moment from 'moment';
import { celularMask } from "../../../components/InputTextMask";
import ChatBotComponent from './ChatBotComponent';
import ReclameAquiComponent from './ReclameAquiComponent';
import FacebookMessengerComponent from './FacebookMessengerComponent';
import InstagramComponent from './InstagramComponent';
import MidiaSocialIcone from '../../../components/MidiaSocialIcone';
import {BrowserView, MobileView} from 'react-device-detect';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCircleLeft
} from "@fortawesome/free-solid-svg-icons";
import DommusBarraTitulo from "../../../components/DommusBarraTitulo";
import ItemBarraTitulo from "../../../components/DommusBarraTitulo/ItemBarraTitulo";
import DommusScrollAnchor from "../../../components/DommusScrollAnchor";
import PresencialComponent from './PresencialComponent';

export default function DetalheAtendimento({callbackVoltar, children}) {
    const { atendimentoAtivo, setAtendimentoAtivo } = useContext(AtendimentoContext);
    const ws = useContext(WebSocketContext);
    const [titleToggle] = useState('Atendimento');

    useEffect(() => {
        ws.channel.bind('atendimento.' + atendimentoAtivo.id, res => {
            if (atendimentoAtivo.id === res.id_atendimento) {
                atualizaAtendimento(atendimentoAtivo.id);
            }
        });

        return () => {
            ws.channel.unbind('atendimento.' + atendimentoAtivo.id);
        }
    }, [atendimentoAtivo.id]);

    // useEffect(() => {
    //     if(atendimentoAtivo.arquivado !== "1"){
    //         atualizaAtendimento(atendimentoAtivo.id);
    //     }
    // }, [data])

    function atualizaAtendimento(id_atendimento) {
        caAPI.atendimento.find(id_atendimento).then(res => {
            setAtendimentoAtivo(res.data)
        }).catch(error => {
            if(error?.response?.status == 404) {
                Swal.fire({
                    titleText: "Erro",
                    text: "Você não possui permissão para visualizar este atendimento.",
                    icon: 'error'
                });
                setAtendimentoAtivo(null)

                return
            }
            Swal.fire({
                titleText: "Erro",
                text: "Houve um problema ao atualizar as mensagens",
                icon: 'error'
            });
            return;
        })
    }

    const contatoDestinoComMascara = useMemo(()=>{
        switch(atendimentoAtivo.meio_comunicacao.slug){
            case "whatsapp": case "voip": case "sms":
                return celularMask(atendimentoAtivo.contato_destino)
            default:
                return (atendimentoAtivo.contato_destino)
        }
    }, [atendimentoAtivo]);

    const AtendimentoComponent = useMemo(() => {
        if (atendimentoAtivo) {
            switch (atendimentoAtivo.meio_comunicacao.slug) {
                case "whatsapp":
                    return <WhatsappComponent />
                case "sms":
                    return <SmsComponent atendimento={atendimentoAtivo} />
                case "email":
                    return <EmailComponent atendimento={atendimentoAtivo} AtualizarListaEmails={atualizaAtendimento} />
                case "voip":
                    return <VoipComponent />
                case "chatbot":
                    return <ChatBotComponent atendimento={atendimentoAtivo} />
                case "reclame-aqui":
                    return <ReclameAquiComponent atendimento={atendimentoAtivo}/>
                case "facebook-messenger":
                    return <FacebookMessengerComponent atendimento={atendimentoAtivo}/>
                case "instagram":
                    return <InstagramComponent atendimento={atendimentoAtivo}/>
                case "presencial":
                    return <PresencialComponent/>
                default:
                    return <div>{atendimentoAtivo.meio_comunicacao.nome} não é suportado</div>
            }
        }
    }, [atendimentoAtivo])

    const title = () => {
        return (
            <>
                <div className="titleToggle">
                    {titleToggle}
                </div>
                <div className="contatoToggle">
                    {/* <div className="contatoInfo">
                        <div>
                            {atendimentoAtivo.contato_origem &&
                                <>
                                    <OverlayTrigger
                                        key="iconOrigem"
                                        placement="top"
                                        overlay={
                                            <Tooltip id={`tooltip-editar`}>
                                                Recebido
                                            </Tooltip>
                                        }
                                    >
                                        <span>De:</span>
                                    </OverlayTrigger>
                                    {Number.isInteger(atendimentoAtivo.contato_origem) ? celularMask(atendimentoAtivo.contato_origem.substring(3)) : atendimentoAtivo.meio_comunicacao.nome}
                                </>
                            }
                        </div>

                    </div> */}
                    <div className="contatoInfo">
                        <div className='icon-meio_comunicao'>
                            <MidiaSocialIcone midia={atendimentoAtivo.meio_comunicacao?.slug|| null} />
                        </div>
                        <OverlayTrigger
                            key="IconMeioComunicacao"
                            placement="top"
                            overlay={
                                <Tooltip id={`tooltip-editar`}>
                                    {contatoDestinoComMascara}
                                </Tooltip>
                            }
                            >
                            <div className='nome-contato'>
                                {atendimentoAtivo.nome ? atendimentoAtivo.nome : contatoDestinoComMascara}
                            </div>
                        </OverlayTrigger>
                        <div className="dataContato"> {'\u2B95'} </div>
                        <OverlayTrigger
                            key="IconDataContato"
                            placement="top"
                            overlay={
                                <Tooltip id={`tooltip-editar`}>
                                    Última atualização
                                </Tooltip>
                            }
                            >
                            <div className='data-hora-contato'>
                                <div className="dataContato">
                                    {moment(atendimentoAtivo.dtatendimento).format('DD/MM/YYYY')}
                                </div>
                                <div className="dataContato"> às </div>
                                <div className="dataContato">
                                    {moment(atendimentoAtivo.dtatendimento).format('HH:mm')}
                                </div>
                            </div>
                        </OverlayTrigger>
                    </div>
                </div>
            </>
        )
    }

    const mobileTitle = () => {
        return (
            <>
                <div className="contatoToggle">
                    {/* <div className="contatoInfo">
                        <div>
                            {atendimentoAtivo.contato_origem &&
                                <>
                                    <OverlayTrigger
                                        key="iconOrigem"
                                        placement="top"
                                        overlay={
                                            <Tooltip id={`tooltip-editar`}>
                                                Recebido
                                            </Tooltip>
                                        }
                                    >
                                        <span>De:</span>
                                    </OverlayTrigger>
                                    {Number.isInteger(atendimentoAtivo.contato_origem) ? celularMask(atendimentoAtivo.contato_origem.substring(3)) : atendimentoAtivo.meio_comunicacao.nome}
                                </>
                            }
                        </div>

                    </div> */}
                    <div className="contatoInfo">
                        <div className='icon-meio_comunicao'>
                            <MidiaSocialIcone
                             midia={atendimentoAtivo.meio_comunicacao?.slug|| null}
                             contatoOrigem={atendimentoAtivo.origem?.descricao}
                            />
                        </div>
                        <OverlayTrigger
                            key="IconMeioComunicacao"
                            placement="top"
                            overlay={
                                <Tooltip id={`tooltip-editar`}>
                                    {contatoDestinoComMascara}
                                </Tooltip>
                            }
                            >
                            <div className='nome-contato'>
                                {atendimentoAtivo.nome ? atendimentoAtivo.nome : contatoDestinoComMascara}
                            </div>
                        </OverlayTrigger>
                        <div className="dataContato"> {'\u2B95'} </div>
                        <OverlayTrigger
                            key="IconDataContato"
                            placement="top"
                            overlay={
                                <Tooltip id={`tooltip-editar`}>
                                    Última atualização
                                </Tooltip>
                            }
                            >
                            <div className='data-hora-contato'>
                                <div className="dataContato">
                                    {moment(atendimentoAtivo.dtatendimento).format('DD/MM/YYYY')}
                                </div>
                                <div className="dataContato"> às </div>
                                <div className="dataContato">
                                    {moment(atendimentoAtivo.dtatendimento).format('HH:mm')}
                                </div>
                            </div>
                        </OverlayTrigger>
                    </div>
                </div>
            </>
        )
    }

    return (<>
            <BrowserView>
              <DommusToggle title={title()} toggle={true} className="atendimentoCol"
                bodyClass="dommus-toggle-height-fixed center-container-toggle height-coluna-atendimento"
                headerClass="dommus-toggle-sem-margem-bottom">
                {AtendimentoComponent}
              </DommusToggle>
            </BrowserView>
            <MobileView>
              <div className="mobile-view-barra-titulo">
                <DommusBarraTitulo>
                  <ItemBarraTitulo>
                  <Button onClick={callbackVoltar} variant="light">
                    <FontAwesomeIcon icon={faCircleLeft} />
                  </Button>
                  {
                    mobileTitle()
                  }
                  </ItemBarraTitulo>
                </DommusBarraTitulo>
              </div>
              <Tab.Container defaultActiveKey="atendimento">
                <Row>
                  <Col sm={12}>
                    <Nav fill variant="tabs" justify>
                      <Nav.Item>
                        <Nav.Link eventKey="atendimento">Atendimento</Nav.Link>
                      </Nav.Item>
                      <Nav.Item>
                        <Nav.Link eventKey="detalhes">Detalhes</Nav.Link>
                      </Nav.Item>
                    </Nav>
                  </Col>
                </Row>
                <Row>
                  <Col sm={12}>
                    <Tab.Content className="mobileTab">
                      <Tab.Pane eventKey="atendimento">
                        {AtendimentoComponent}
                        <DommusScrollAnchor></DommusScrollAnchor>
                      </Tab.Pane>
                      <Tab.Pane eventKey="detalhes">
                        {children}
                      </Tab.Pane>
                    </Tab.Content>
                  </Col>
                </Row>
              </Tab.Container>
            </MobileView>
            </>
    );
}
