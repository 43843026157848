import React, { useCallback, useContext, useEffect, useState } from "react";
import DommusBarraTitulo from "../../components/DommusBarraTitulo";
import ItemBarraTitulo from "../../components/DommusBarraTitulo/ItemBarraTitulo";
import RegistroAtendimento from "./RegistroAtendimento";
import { Col, Row, Container, Tooltip, OverlayTrigger, Button, Badge } from "react-bootstrap";
import DetalheAtendimento from "./DetalheAtendimento";
import DetalheProcesso from "./DetalheProcesso";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "./style.scss";
import {
  faBroom,
  faBroomBall,
  faPlusCircle,
  faSearch,
} from "@fortawesome/free-solid-svg-icons";
import ModalDommus from "../../components/Modal";
import ModalNovoAtendimento from "./ModalNovoAtendimento";
import { trackPromise } from "react-promise-tracker";
import caAPI from "../../services/caAPI";
import AtendimentoService from "../../services/AtendimentoService";
import { WebSocketContext } from "../../contexts/WebSocketContext";
import { AtendentesContext } from "../../contexts/AtendentesContext";
import { successToast } from "../../components/DommusToast";
import moment from "moment";
import { AtendimentoContext } from "../../contexts/AtendimentoContext";
import { VoipContext } from "../../contexts/VoipContext";
import FiltrarAtendimentos from "./FiltrarAtendimentos";
import AtendimentoIntervaloDatasPicker from "./AtendimentoIntervaloDatasPicker";
import { celularMask } from "../../components/InputTextMask";
import NotificationDommus from "../../helpers/NotificationsHelper";
import { errorToast, infoToast } from "../../components/DommusToast";
import BaciaAtendimentoWrapper from "./BaciaAtendimentoWrapper";
import { ItensAtalhoContext } from "../../contexts/ItensAtalhosContext";
import RegistroChamadasPerdidas from "../../components/Anotacoes/ChamadasPerdidas";
import urlHelper from "../../helpers/UrlHelper";
import {BrowserView, MobileView, isMobile} from 'react-device-detect';
import Swal from "sweetalert2";

export default function PortalCliente(props) {
  const [openModalNovoAtendimento, setOpenModalNovoAtendimento] =
    useState(false);
  const [countFiltros, setCountFiltros] = useState(0);
  const [filter, setFilter] = useState(false);
  const [salvarFiltros, setSalvarFiltros] = useState(null);
  const {recarregarItensAtalho} = useContext(ItensAtalhoContext);
  const [atendimentoUrl, setAtendimentoUrl] = useState(null)
  const [tipoDataFiltro, setTipoDataFiltro] = useState(null)
  const [dataInicio, setDataInicio] = useState()
  const [dataFim, setDataFim] = useState()


  const {
    atualizaLista,
    listaAtendimentos,
    setListaAtendimentos,
    setAtendimentoAtivo,
    atendimentoAtivo,
    openFiltros,
    setOpenFiltros,
    filtro,
    handleAdiconaLista,
    handleRemoveLista,
    loadingMensagens,
    checkExisteIdEmLista,
    atualizarTotaisAtendimentos
  } = useContext(AtendimentoContext);
  const {
    recarregarListaAtendentes,
    testarUsuarioAtendeInputMensagem,
  } = useContext(AtendentesContext);
  const { channel } = useContext(WebSocketContext);
  const { inCallStatus } = useContext(VoipContext);

  useEffect(() => {
    async function load(){
      let granted = false;
      if (Notification.permission === 'granted') {
          granted = true;
      } else if (Notification.permission !== 'denied') {
          let permission = await Notification.requestPermission();
          granted = permission === 'granted' ? true : false;
      }
      // show notification or the error message
    }
    load()
  }, [])

  const abrirAtendimento = (atendimento) => {
    if (!(inCallStatus) && !(loadingMensagens)) {
      const indiceAtendimento = listaAtendimentos.findIndex(
        (el) => Number(el.id) === Number(atendimento.id)
      );
      trackPromise(caAPI.atendimento.find(atendimento.id).then(res => {
        const novoAtendimento = Object.assign({}, res.data);
        novoAtendimento.conteudos = res.data.conteudos
        const novaLista = listaAtendimentos.slice();
        novoAtendimento.msg_nao_lidas = 0;
        if (indiceAtendimento > -1) {
          novaLista.splice(indiceAtendimento, 1, novoAtendimento);
        }
        novoAtendimento.hasMore = res.data.hasMore || false;
        setAtendimentoAtivo(novoAtendimento);
        setListaAtendimentos(novaLista);
        atualizarTotaisAtendimentos()
      }));

      recarregarItensAtalho();

    }else{
      errorToast.fire({ text: "Em ligação." });
    }
  };

  useEffect(() => {
    const idAtendimento = urlHelper.obterParametroDaUrl('idAtendimento')
    if(idAtendimento && listaAtendimentos.length && !atendimentoUrl) {
      const atendimentoAbrir = listaAtendimentos.find(atendimento => atendimento.id === Number(idAtendimento))
      if(atendimentoAbrir) {
        setAtendimentoAtivo(atendimentoAbrir)
        setAtendimentoUrl(atendimentoAbrir)
        abrirAtendimento(atendimentoAbrir);
      }
    }
  }, [listaAtendimentos])

  useEffect(() => {
    channel.unbind("nova-mensagem");
    channel.unbind("atendimento-assumido");
    channel.unbind("atendimento-arquivado");
    channel.unbind("atendimento-finalizado");

    if(listaAtendimentos){
      channel.bind("nova-mensagem", function (data) {
        if(data.id_atendimento && data.id_contato_origem){
          let atendimentoTeste = {
            id: data.id_atendimento,
            origem:{
              id_contato_origem: data.id_contato_origem
            },
            atendente: data.id_atendente
          }
          if (testarUsuarioAtendeInputMensagem(atendimentoTeste) ||
            (
              atendimentoTeste?.origem &&
              atendimentoTeste?.origem?.id_contato_origem &&
              AtendimentoService.testarUsuarioAtualVeOrigem(atendimentoTeste?.origem?.id_contato_origem)
            )
          ) {

            caAPI.atendimento.findWithoutReading(data.id_atendimento).then((response)=>{
              let atendimento = response.data;
              if (
                data &&
                data.id_atendimento &&
                (!atendimentoAtivo ||
                  (atendimentoAtivo &&
                    atendimentoAtivo.id &&
                    Number(atendimentoAtivo.id) !== Number(data.id_atendimento)))
              ) {
                verificaMeioComunicacaoNotificacao(data, atendimento)
                handleAddBadget(atendimento);
              }
              handleAdiconaLista(atendimento);
            }).catch((erro)=>{
              Swal.fire({
                  titleText: "Erro",
                  text: "Houve um problema ao buscar atendimento " + erro,
                  icon: 'error'
              });
            });
          }
        }
      });

      channel.bind("atendimento-assumido", function (data) {
        if(data.atendimento && data.id_contato_origem){
          let atendimentoTeste = {
            id: data.atendimento,
            origem:{
              id_contato_origem: data.id_contato_origem
            },
            atendente: data.id_atendente
          }

          if (
            testarUsuarioAtendeInputMensagem(atendimentoTeste) ||
            (
              atendimentoTeste?.origem &&
              atendimentoTeste?.origem?.id_contato_origem &&
              AtendimentoService.testarUsuarioAtualVeOrigem(atendimentoTeste?.origem?.id_contato_origem)
            )
          ){
            caAPI.atendimento.findWithoutReading(data.atendimento).then((response)=>{
              let atendimento = response.data;
              let idUsuario = Number(caAPI.getLocalState("idUsuario"));
              if (
                atendimento &&
                atendimento.id &&
                (atendimentoAtivo &&
                    atendimentoAtivo.id &&
                    Number(atendimentoAtivo.id) === Number(atendimento.id)) &&
                ( Number(atendimento.atendente) !== idUsuario ) && atendimento.atendente
              ) {
                infoToast.fire({text:"Atendimento já respondido"});
                setAtendimentoAtivo(null);
              }
              handleAdiconaLista(atendimento);
            }).catch((erro)=>{
              Swal.fire({
                titleText: "Erro",
                text: "Houve um problema ao buscar atendimento " + erro,
                icon: 'error'
              });
            });
          } else {
            if((atendimentoAtivo?.id && atendimentoTeste?.id && atendimentoTeste?.atendente) &&
              (Number(atendimentoAtivo.id) === Number(atendimentoTeste.id))) {
              infoToast.fire({text:"Atendimento já respondido"});
              setAtendimentoAtivo(null);
              handleRemoveLista(atendimentoTeste)
            }
          }
        }
      });

      channel.bind("atendimento-arquivado", function (data) {
        if(data?.id_atendimento && checkExisteIdEmLista(data?.id_atendimento)){
          if(atendimentoAtivo && (atendimentoAtivo.id == data.id_atendimento)){
            infoToast.fire({text:"Atendimento arquivado"});
            setAtendimentoAtivo(null);
          }
          handleRemoveLista({id: data.id_atendimento});

        }
      });

      channel.bind("atendimento-finalizado", function (data) {
        if(data?.id_atendimento && checkExisteIdEmLista(data?.id_atendimento)){
          caAPI.atendimento.find(data.id_atendimento).then(res => {
            if(atendimentoAtivo && (atendimentoAtivo.id == data.id_atendimento)){
              infoToast.fire({text:"Atendimento finalizado"});
              setAtendimentoAtivo(res.data)
            }
            handleAdiconaLista(res.data);
          }).catch(e => {
              console.log("Erro ao buscar atendimento finalizado." + data.id_atendimento);
              return;
          })
        }
      });
    }
    return () => {
      channel.unbind("nova-mensagem");
      channel.unbind("atendimento-assumido");
      channel.unbind("atendimento-arquivado");
      channel.unbind("atendimento-finalizado");

    }
  }, [channel, atendimentoAtivo, listaAtendimentos]);

  useEffect(() => {
    setFilter(JSON.parse(caAPI.getLocalState('filtrosCount')))
  }, [filtro])

  function verificaMeioComunicacaoNotificacao(atendimento){
    if(atendimento?.meio_comunicacao === 4){
      NotificationDommus.showNotification(`Você recebeu um novo email de ${atendimento?.contato_destino}`, 'Novo E-mail')
    }else if(atendimento?.meio_comunicacao === 2){
      NotificationDommus.showNotification(`${celularMask(atendimento?.contato_destino)} disse: ${atendimento?.ultima_msg?.conteudo.length > 70 ? atendimento?.ultima_msg?.conteudo.substring(0, 70) + '...' : atendimento?.ultima_msg?.conteudo.length}`, 'Nova Mensagem')
    }else{
      NotificationDommus.showNotification(`Você recebeu uma nova mensagem de ${celularMask(atendimento?.contato_destino)} .`, 'Nova Mensagem');
    }
  }

  function handleAddBadget(data) {
    successToast.fire(
      '<span style="color:#FFFF"> mensagem recebida no atendimento #' +
      data.id +
      " (" +
      ( data.msg_nao_lidas || 0 ) +
      ") mensagens não lidas </span>"
    );
  }

  function handleServicesCleanByCommunicationType(){
      const cleanedListAtendimentos = listaAtendimentos.filter(atendimento => {
        if(filter.meio_comunicacao.includes(atendimento.meio_comunicacao.id)){
            return atendimento;
        }
      })

      setListaAtendimentos(cleanedListAtendimentos)
  }

  useEffect(() => {
    let countLet = 0;
    if (typeof filter === 'object') {
      for (let attr in filter) {
        if ((Array.isArray(filter[attr]) && filter[attr].length) || (!Array.isArray(filter[attr]) && filter[attr])) {
          countLet += 1;
        }
      }
    }
    setCountFiltros(countLet);
  }, [filter, setCountFiltros]);


  return (
    <>
    <Container fluid className={`${(isMobile && "restringir-height-container") || ""}`}>
      <BrowserView>
        <DommusBarraTitulo>
          <ItemBarraTitulo classe="titulo-ca">Central de Atendimento</ItemBarraTitulo>
          <ItemBarraTitulo classe="periodo">
            <Badge className="badge-identifica-data-tela-inicial mr-3" variant="info">{tipoDataFiltro == 2 ? "Data de Criação" : "Data de Atualização"}</Badge>
            <div>
              <AtendimentoIntervaloDatasPicker tipoBusca={tipoDataFiltro}></AtendimentoIntervaloDatasPicker>
            </div>
          </ItemBarraTitulo>
          <ItemBarraTitulo classe="btn" onClick={() => setOpenFiltros(true)}>
            <OverlayTrigger
              key="buttonPesquisa"
              placement="top"
              overlay={
                <Tooltip id={`tooltip-Pesquisa`}>
                  Pesquisar
                </Tooltip>
              }
            >
              <FontAwesomeIcon icon={faSearch} color="#265d9c" />
            </OverlayTrigger>
            {countFiltros >= 0 ? <div className="indicativoFiltro2">{countFiltros}</div> : <></>}
          </ItemBarraTitulo>
          <ItemBarraTitulo
            classe="btn"
            onClick={() => {
              recarregarListaAtendentes();
              setOpenModalNovoAtendimento(true);
            }}
          >
            <OverlayTrigger
              key="buttonNovo"
              placement="top"
              overlay={
                <Tooltip id={`tooltip-Novo`}>
                  Iniciar atendimento
                </Tooltip>
              }
            >
              <FontAwesomeIcon icon={faPlusCircle} color="#4caf50" />
            </OverlayTrigger>
          </ItemBarraTitulo>

          <OverlayTrigger key={"btn-clean"}
              placement="top"
              overlay={
                <Tooltip id={`tooltip-Novo`}>
                    Limpa a lista de atendimentos pelo tipo de atendimento no filtro
              </Tooltip>
              }>
                    <ItemBarraTitulo  classe="btn"
                  onClick={handleServicesCleanByCommunicationType}>
                  <FontAwesomeIcon icon={faBroomBall} color="#4caf50" />
                </ItemBarraTitulo>
              </OverlayTrigger>
          <BaciaAtendimentoWrapper />
        </DommusBarraTitulo>
      </BrowserView>
      {
        !atendimentoAtivo && <MobileView className="mobile-view-barra-titulo">
          <DommusBarraTitulo>
            <ItemBarraTitulo>Central de Atendimento</ItemBarraTitulo>
          </DommusBarraTitulo>
        </MobileView>
      }

      <Row style={{ margin: atendimentoAtivo ? "" : "0 0" }}
      className={`${(isMobile && !atendimentoAtivo && "mt-3") || ""}`}>
        <div className="registrosAtendimento" style={{ display: atendimentoAtivo && isMobile ? "none" : "contents" }}>
          <Col
            md={4}
            className={(!(isMobile) && "pr-1")}
            style={{ display: atendimentoAtivo ? "" : "contents" }}
          >
            <RegistroAtendimento
              abrirAtendimento={abrirAtendimento}
              atendimentoAtivoId={atendimentoAtivo && atendimentoAtivo.id}
            />
          </Col>
        </div>
        <Col
          md={5}
          className={(!(isMobile) && "px-1")}
          style={{ display: atendimentoAtivo ? "" : "none" }}
        >
          {atendimentoAtivo && (
            <DetalheAtendimento callbackVoltar={() => setAtendimentoAtivo(null)}>
              {atendimentoAtivo && (
                <RegistroChamadasPerdidas atendimento={atendimentoAtivo} />
              )}
              {atendimentoAtivo && (
                <DetalheProcesso atendimento={atendimentoAtivo}/>
              )}
            </DetalheAtendimento>
          )}
        </Col>

        {!isMobile &&  <Col
            md={3}
            className="pl-1 position-relative"
            style={{ display: atendimentoAtivo ? "" : "none" }}
          >

            {atendimentoAtivo && (
              <RegistroChamadasPerdidas atendimento={atendimentoAtivo} />
            )}
            {atendimentoAtivo && (
              <DetalheProcesso atendimento={atendimentoAtivo}/>
            )}
          </Col>}
      </Row>
      <ModalDommus
        titulo="Novo Atendimento"
        open={openModalNovoAtendimento}
        close={() => setOpenModalNovoAtendimento(false)}
      >
        <ModalNovoAtendimento
          setOpenModalNovoAtendimento={setOpenModalNovoAtendimento}
        />
      </ModalDommus>
      <ModalDommus
        className="modalFiltros"
        open={openFiltros}
        size={"lg"}
        close={() => {
          setOpenFiltros(false);
        }}
        titulo={"Filtrar chamados"}
      >
        <FiltrarAtendimentos setSalvarFiltros={setSalvarFiltros} setTipoDataFiltro={setTipoDataFiltro} setDataInicio={setDataInicio} setDataFim={setDataFim}/>
      </ModalDommus>
    </Container>
    {
      isMobile && <div class="dommus-mobile-navigation">
        <div class="dommus-mobile-navigation-wrapper">
          <ul class="navigation">
            <li>
              <a onClick={() => setOpenFiltros(true)}>
                <OverlayTrigger
                  key="buttonPesquisa"
                  placement="top"
                  overlay={
                    <Tooltip id={'tooltip-Pesquisa'}>
                      Pesquisar
                    </Tooltip>
                  }
                >
                  <FontAwesomeIcon icon={faSearch} />
                </OverlayTrigger>
                {countFiltros >= 0 ? <div className="indicativoFiltro2">{countFiltros}</div> : <></>}
              </a>
            </li>
            <li class="btn-open-navigation">
              <a
                onClick={() => {
                  recarregarListaAtendentes();
                  setOpenModalNovoAtendimento(true);
                }}
              >
                <OverlayTrigger
                  key="buttonNovo"
                  placement="top"
                  overlay={
                    <Tooltip id={'tooltip-Novo'}>
                      Iniciar atendimento
                    </Tooltip>
                  }
                >
                  <FontAwesomeIcon icon={faPlusCircle} />
                </OverlayTrigger>
              </a>
            </li>
            <li>
              <BaciaAtendimentoWrapper />
            </li>
          </ul>
        </div>
      </div>
    }
    </>
  );
}
