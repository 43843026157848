const urlHelper = {
  download: (link) => {
    let fileName;
    fileName = link.split("/").slice(-1)[0];

    let form = document.createElement("form");
    form.setAttribute("method", "post");
    // form.setAttribute("action", `https://arquivos.dommus.com.br/${fileName}`);
    form.setAttribute("action", link);

    form.setAttribute("target", "_blank");
    let input = document.createElement("input");
    input.type = "hidden";
    input.name = "file";
    input.value = link;
    form.appendChild(input);
    document.body.appendChild(form);
    form.submit();
  },
  obterParametroDaUrl: (variable) => {
    let queryString = window.location.search.substring(1);
    let vars = queryString.split("&");
    for (let i=0;i<vars.length;i++) {
      let par = vars[i].split("=");
      if(par[0] == variable){
        return par[1];
      }
    }
    return(false);
  },
  alterarUrlArquivoComNomeOriginal: (url, nomeArquivoOriginal, nomeArquivoS3=null) => {
    if(!url || !nomeArquivoOriginal) {
      return url || null
    }
    const dadosUrl = new URL(url)
    const anexoPrivado = dadosUrl.host == 'arquivos.dommus.com.br'
    if(!nomeArquivoS3 && !anexoPrivado) {
      return null
    }
    if(anexoPrivado) {
      const arquivoS3 = dadosUrl.pathname.split('/').filter(segmento => segmento)
      const nomeAnexoS3 = arquivoS3.pop()
      url = `${dadosUrl.origin}/${arquivoS3.join('/')}/${nomeArquivoOriginal}?a=${btoa(nomeAnexoS3)}`
    } else {
      url = `https://publico.dommus.com.br/${nomeArquivoOriginal}?a=${btoa(nomeArquivoS3)}`
    }
    return url
  }
};

export default urlHelper;
