import React, { useEffect } from "react";
import { Button, Col, Form, Row } from "react-bootstrap";
import { FormProvider, useForm } from "react-hook-form";
import { CampoFiltrosGlobaisDashboardDinamico } from "../CampoFiltrosGlobaisDashboardDinamico";
import DommusToggle from "../DommusToggle";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEraser, faFilter, faSearch } from "@fortawesome/free-solid-svg-icons";

export function FiltrosGlobaisDashboardDinamico({ configuracaoFiltros, atualizarFiltrosGlobais }) {
    const methods = useForm()

    function atualizarFiltros(dados) {
        let filtrosTratados = {};
        for(let[chave, valor] of Object.entries(dados)) {
            if(Array.isArray(valor) && valor.length) {
                filtrosTratados[chave] = valor.map(opcao => isNaN(parseInt(opcao.value)) && opcao.value || Number(opcao.value))
            } else if(!Array.isArray(valor) && valor) {
                filtrosTratados[chave] = valor
            }
        }
        atualizarFiltrosGlobais(filtrosTratados)
    }

    function limparFiltros() {
        methods.reset()
        atualizarFiltros({})
    }

    useEffect(()=>{
      atualizarFiltros(methods?.getValues());
    },[])

    return configuracaoFiltros?.length && (
        <DommusToggle title={<div><FontAwesomeIcon icon={faFilter}/> Filtros</div>}>
            <FormProvider {...methods}>
                <Form onSubmit={methods.handleSubmit(atualizarFiltros)}>
                    <Row className="px-3">
                        {configuracaoFiltros.map((configuracao, index) => (
                            <Form.Group key={`campo-filtro-${index}`} as={Col} md={configuracao.tamanhoColuna || 4}>
                                {configuracao.descricaoCampo && <Form.Label className="label-formulario">{configuracao.descricaoCampo}</Form.Label>}
                                <CampoFiltrosGlobaisDashboardDinamico configuracao={configuracao}/>
                            </Form.Group>
                        ))}
                    </Row>
                    <Row className="justify-content-end mx-3">
                        <Button onClick={limparFiltros} variant="secondary" className="mr-3">
                            <FontAwesomeIcon icon={faEraser}/> Limpar
                        </Button>
                        <Button type="submit">
                            <FontAwesomeIcon icon={faSearch}/> Buscar
                        </Button>
                    </Row>
                </Form>
            </FormProvider>
        </DommusToggle>
    ) || <></>
}
