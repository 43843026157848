import React, { useEffect, useState } from 'react';
import DommusBarraTitulo from '../../components/DommusBarraTitulo';
import ItemBarraTitulo from '../../components/DommusBarraTitulo/ItemBarraTitulo';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import './index.css'
import { AbaDashboard } from '../../components/AbaDashboard';
import { Container, OverlayTrigger, Tab, Tabs, Tooltip } from 'react-bootstrap';
import { faExpandArrowsAlt, faPlusCircle } from '@fortawesome/free-solid-svg-icons';
import { CategoriaGraficoService } from '../../services/CategoriaGraficosService';
import { errorToast } from '../../components/DommusToast';
import { PerfilUsuarioService } from '../../services/PerfilUsuarioService';

export function Dashboards() {
	const [categoriasGraficosDisponiveis, setCategoriasGraficosDisponiveis] = useState([])
	const [carregamentoGraficosDisponiveis, setCarregamentoGraficosDisponiveis] = useState(false)
	const [erroCarregamentoGraficosDisponiveis, setErroCarregamentoGraficosDisponiveis] = useState(false)
	const [carregamentoGraficosUsuario, setCarregamentoGraficosUsuario] = useState(false)
	const [erroCarregamentoGraficosUsuario, setErroCarregamentoGraficosUsuario] = useState(false)
	const [configuracoesUsuario, setConfiguracoesUsuario] = useState({})
	const [bibliotecaGraficosAberta, setBibliotecaGraficosAberta] = useState(false)
	const menu = document.querySelector('.sidebar-wrapper')

	useEffect(() => {
		if(!categoriasGraficosDisponiveis.length && erroCarregamentoGraficosDisponiveis === false) {
			setCarregamentoGraficosDisponiveis(true)
			CategoriaGraficoService.buscarVisiveisUsuario()
				.then((response) => {
					setCategoriasGraficosDisponiveis(response.data)
					setCarregamentoGraficosDisponiveis(false)
				}).catch((error) => {
					console.log(error)
					console.log(error?.response)
					errorToast.fire({ text:'Houve um erro ao buscar os gráficos disponíveis.' })
					setCarregamentoGraficosDisponiveis(false)
					setErroCarregamentoGraficosDisponiveis(true)
				})
		}
	}, [erroCarregamentoGraficosDisponiveis])

	useEffect(() => {
		if(!Object.keys(configuracoesUsuario || {}).length && erroCarregamentoGraficosUsuario === false) {
			setCarregamentoGraficosUsuario(true)
			PerfilUsuarioService.buscarDadosPerfil()
				.then(response => {
					setConfiguracoesUsuario(response.data?.data?.tela_dashboards || {})
					setCarregamentoGraficosUsuario(false)
				}).catch(error => {
					console.log(error)
					console.log(error?.response)
					errorToast.fire({ text:'Houve um erro ao buscar os gráficos do usuário.' })
					setCarregamentoGraficosUsuario(false)
					setErroCarregamentoGraficosUsuario(true)
				})
		}
	}, [erroCarregamentoGraficosUsuario])

	function alterarTelaCheia() {
        if(document.fullscreenElement) {
			sairTelaCheia();
		} else {
            entrarTelaCheia();
        }
    }

    function entrarTelaCheia() {
		if(!document.fullscreenElement && !document.mozFullScreenElement && !document.webkitFullscreenElement && !document.msFullscreenElement) {
			menu?.classList?.add('d-none')
			if (document.documentElement.requestFullscreen) {
				document.documentElement.requestFullscreen();
			} else if (document.documentElement.msRequestFullscreen) {
				document.documentElement.msRequestFullscreen();
			} else if (document.documentElement.mozRequestFullScreen) {
				document.documentElement.mozRequestFullScreen();
			} else if (document.documentElement.webkitRequestFullscreen) {
				document.documentElement.webkitRequestFullscreen(Element.ALLOW_KEYBOARD_INPUT);
			}
		}
	}
  
	function sairTelaCheia() {
		if(document){
		  	menu?.classList?.remove('d-none')
			if (document.exitFullscreen) {
				document.exitFullscreen();
			} else if (document.msExitFullscreen) {
				document.msExitFullscreen();
			} else if (document.mozCancelFullScreen) {
				document.mozCancelFullScreen();
			} else if (document.webkitExitFullscreen) {
				document.webkitExitFullscreen();
			}
		}
	}

	return (
      <Container fluid>
		<DommusBarraTitulo>
			<ItemBarraTitulo classe={''}>
			  Dommus Insights	
			</ItemBarraTitulo>
			<OverlayTrigger placement="left" overlay={<Tooltip id="tooltip-grafico">Adicionar Gráfico</Tooltip>}>
				<ItemBarraTitulo classe="btn" onClick={() => setBibliotecaGraficosAberta(true)}>
					<FontAwesomeIcon icon={faPlusCircle} color="var(--success)"/>
				</ItemBarraTitulo>
			</OverlayTrigger>
			<OverlayTrigger placement="left" overlay={<Tooltip id="tooltip-tela-cheia">Visualizar em Tela Cheia</Tooltip>}>
				<ItemBarraTitulo classe="btn" onClick={() => alterarTelaCheia()}>
					<FontAwesomeIcon icon={faExpandArrowsAlt} color="var(--dark)"/>
				</ItemBarraTitulo>
			</OverlayTrigger>
		</DommusBarraTitulo>
		{categoriasGraficosDisponiveis.length && (
			<Tabs>
				{categoriasGraficosDisponiveis.map(categoria => (
					<Tab key={`aba-${categoria.id_categoria_grafico}`} title={categoria.nome} eventKey={categoria.nome} className="p-3 bg-white border rounded">
						<AbaDashboard
							idCategoria={String(categoria.id_categoria_grafico)}
							carregamentoGraficosDisponiveis={carregamentoGraficosDisponiveis}
							carregamentoGraficosUsuario={carregamentoGraficosUsuario}
							configuracoesUsuario={configuracoesUsuario}
							erroCarregamentoGraficosDisponiveis={erroCarregamentoGraficosUsuario}
							erroCarregamentoGraficosUsuario={erroCarregamentoGraficosUsuario}
							graficosDisponiveis={categoria.graficos}
							setConfiguracoesUsuario={setConfiguracoesUsuario}
							setErroCarregamentoGraficosDisponiveis={setErroCarregamentoGraficosDisponiveis}
							setErroCarregamentoGraficosUsuario={setErroCarregamentoGraficosUsuario}
							bibliotecaGraficosAberta={bibliotecaGraficosAberta}
							setBibliotecaGraficosAberta={setBibliotecaGraficosAberta}
						/>
					</Tab>
				))}
			</Tabs>
		) || <></>}
	</Container>
  )
}
