import React, { useEffect, useMemo, useRef, useContext, useState } from "react";
import {
  Accordion,
  Card,
  Row,
  Col,
  Button,
  AccordionContext,
  useAccordionToggle,
  Spinner,
  OverlayTrigger,
  Tooltip,
  Alert,
} from "react-bootstrap";
import moment from "moment";
import ModalDommus from "../../../../components/Modal";
import ModalEmail from "./ModalEmail";
import Swal from "sweetalert2";
import caAPI from "../../../../services/caAPI";
import "./style.scss";
import { Icon } from "@iconify/react";
import mailSendLine from "@iconify/icons-ri/mail-send-line";
import InfiniteScroll from "react-infinite-scroll-component";
import { WebSocketContext } from "../../../../contexts/WebSocketContext";
import { Templates } from "./templates";
import { trackPromise } from "react-promise-tracker";
import { faArrowAltCircleLeft, faDownload, faFileAlt, faLayerGroup } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import DOMPurify from 'dompurify';
import { DommusThumbnail } from "../../../../components/DommusThumbnail";
import { DocumentViewer } from "react-documents";
import DommusLoading from "../../../../components/DommusLoading/DommusLoading.js";

export function ContextAwareToggle({ children, eventKey, callback }) {
  const currentEventKey = useContext(AccordionContext);
  const decoratedOnClick = useAccordionToggle(
    eventKey,
    () => callback && callback(eventKey)
  );
  const isCurrentEventKey = currentEventKey === eventKey;
  return (
    <div
      style={{
        backgroundColor: isCurrentEventKey ? "#265d9c" : "rgba(0,0,0,.03)",
        color: isCurrentEventKey ? "white" : "black",
        padding: ".75rem 1.25rem",
        marginbottom: "0",
        borderbottom: "1px solid rgba(0,0,0,.125)",
      }}
      onClick={decoratedOnClick}
    >
      {children}
    </div>
  );
}

export default function EmailComponent({ atendimento, AtualizarListaEmails }) {
  const [listaMensagens, setListaMensagens] = useState([]);
  const [openModalEmail, setOpenModalEmail] = useState(false);
  const messageContainerRef = useRef();
  const [atendimentoAtivo, setAtendimentoAtivo] = useState(atendimento);
  const [hasMore, setHasMore] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [assinatura, setAssinatura] = useState("");
  const [iniciado, setIniciado] = useState(false);
  const [modalTemplateAberto, setModalTemplateAberto] = useState(false);
  const [anexoSelecionadoVisualizacao, setAnexoSelecionadoVisualizacao] = useState(null);
  const [anexoCarregado, setAnexoCarregado] = useState(false);

  const handleDownloadAttachment = (attachment) => {
    const abrirArquivo = (url) => {
      let a = document.createElement("a");
      a.target = "_blank";
      a.href = url;
      a.click();
    }
    if(attachment.url) {
      abrirArquivo(attachment.url)
    } else {
      trackPromise(caAPI.atendimento.email.getAnexo(attachment.file_name)).then((res) => abrirArquivo(res.data.url));
    }
  };

  useEffect(() => {
    caAPI.atendimento.email
      .getAssinatura()
      .then((res) => {
        setAssinatura(
          res.data.assinatura
            ? JSON.parse(res.data.assinatura)
            : res.data.assinatura
        );
      })
      .catch((err) => {
        Swal.fire({
          titleText: "Erro",
          text: "Houve um problema ao buscar assinatura",
          icon: "error",
        });
        return;
  });
    setIniciado(!!atendimento.conteudos?.length)
    setAtendimentoAtivo(atendimento);
  }, [atendimento]);

  useEffect(() => {
    setListaMensagens(atendimentoAtivo.conteudos);
    setHasMore(atendimentoAtivo.hasMore);
  }, [atendimentoAtivo]);

  const { channel } = useContext(WebSocketContext);
  useEffect(() => {
    channel.unbind('email.' + atendimentoAtivo.id);
    channel.bind("email." + atendimentoAtivo.id, function (data) {
      const newMessage = JSON.parse(data.mensagem);
      setListaMensagens((messages) => [...messages, newMessage]);
    });
  }, [channel, atendimentoAtivo]);

  const handleLoadMoreMessages = () => {
    const messages = [...listaMensagens];

    caAPI.atendimento
      .getMessages(atendimento.id, Number(currentPage) + 1)
      .then((res) => {
        messages.push(res.data);
        setCurrentPage(res.data.currentPage);
        setListaMensagens([...listaMensagens, ...res.data.conteudos]);
        setHasMore(res.data.hasMore);
      })
      .catch((err) => {
        Swal.fire({
          titleText: "Erro",
          text: "Houve um problema ao buscar mais mensagens",
          icon: "error",
        });
        return;
      });
  };

  const handlePreviewFile = (url, extensao) => {
    let typeViewer = "";

    switch(extensao){
       case 'png':
       case 'jpg':
       case 'jpeg':
       case 'svg':
       case 'pdf':
        typeViewer = 'url';
        extensao = "";
        break;
       case 'docx':
       case 'doc':
       case 'xlsx':
       case 'xlsm': 
        typeViewer = 'office';
        break;
       default:
        typeViewer = 'google';
        break;      
    }
    const data = {
      url: url,
      typeViewer: typeViewer,
      extension: extensao
    }

    setAnexoSelecionadoVisualizacao(data);
  }
  
  const mensagens = useMemo(() => {
    if (listaMensagens) {
      return listaMensagens.sort((a, b) => {
        return new Date(b.criado_em) - new Date(a.criado_em)
      }).map((msg) => {
        let detalhes = JSON.parse(msg?.detalhes || '{}')?.data ?? {};
        const conteudo = msg?.conteudo?.replace(/\r\n|\r|\n/g, "<br/>")
        const arquivosNoCorpoSemColchetes = conteudo?.match(/\[[^\]]+\]/g)?.map(nome => nome.slice(1, -1)) || [];
        let textoModificado = conteudo;
        if(Array.isArray(arquivosNoCorpoSemColchetes)){
          arquivosNoCorpoSemColchetes.forEach((arquivo, index) => {
            const arquivoEncontrado = detalhes?.attachments && detalhes?.attachments?.find(anexo => anexo.original_name == arquivo)
            const tagImg = `<img class="img-fluid pr-3" src="${arquivoEncontrado?.url}" alt="${arquivo}">`;
            textoModificado = textoModificado?.replace(`[${arquivo}]`, tagImg);
          });
        }
        
        return (
          <div className="email" key={msg.id}>
            <div className="header email-header">
              <Card>
                <Accordion>
                  <div className="accordion_iten">
                    <ContextAwareToggle
                      as={Card.Header}
                      cardName={msg?.id}
                      title={detalhes?.assunto}
                      eventKey={msg?.id}
                    >
                      <Row>
                        <Col>
                          <div className="dadosAssunto">
                            {msg?.direcao === "enviado" ? (
                              <Icon
                                className="iconeEnviadoRecebido"
                                icon={mailSendLine}
                              />
                            ) : (
                              <Icon
                                className="iconeEnviadoRecebido"
                                icon={mailSendLine}
                                hFlip={true}
                              />
                            )}
                            {detalhes?.assunto}
                          </div>
                        </Col>
                        <Col sm={4}>
                          <div className="data">
                            {moment(msg.criado_em).format("DD/MM/YYYY HH:mm")}
                          </div>
                        </Col>
                      </Row>
                    </ContextAwareToggle>
                  </div>
                  <Accordion.Collapse eventKey={msg.id}>
                    <div className="dadosEnvio">
                      {msg.direcao === "enviado" ? (
                        <div className="dadosPara">Para: {detalhes?.to ?? "---"} </div>
                      ) : (
                        <div className="dadosDe">De: {detalhes?.from ?? "---"}</div>
                      )}
                      {detalhes?.cc ? (
                        <div className="dadosCopiaCortesia">
                          CC:{" "}
                          <span className="emailCopiaCortesia">
                            {" "}
                            {detalhes.cc}{" "}
                          </span>{" "}
                        </div>
                      ) : (
                        <></>
                      )}
                      {detalhes?.bcc ? (
                        <div className="dadosCopiaCortesiaOculta">
                          CCO:{" "}
                          <span className="emailCopiaCortesiaOculta">
                            {detalhes.bcc}{" "}
                          </span>{" "}
                        </div>
                      ) : (
                        <></>
                      )}
                    </div>
                  </Accordion.Collapse>
                </Accordion>
              </Card>
            </div>
            <div className="body" style={{ overflowY: "hidden", overflowX: "auto"}}>
              {(textoModificado && <div dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(textoModificado) }}/>) || <Alert className="mr-3" variant="warning">Nenhum conteúdo a ser exibido.</Alert>}
            </div>
            {detalhes?.attachments && (
                <div className=" mail-attachments-section">
                  {detalhes.attachments.map(function (attachment, index) {
                    return (
                      <OverlayTrigger
                        key={"file"+index}
                        placement="top"
                        overlay={
                          <Tooltip id={`tooltip-`+index}>
                            {attachment?.original_name || attachment?.file_name || ""}
                          </Tooltip>
                        }
                      >
                        <div key={index} className="thumb-anexo">
                          <DommusThumbnail arquivo={attachment} url={attachment?.url} extensao={attachment?.extension} handlePreviewFile={handlePreviewFile}/>
                        </div>
                      </OverlayTrigger>
                    );
                  })}
                </div>
              )}
          </div>
        );
      });
    }
    messageContainerRef.current.scrollIntoView({ behavior: "smooth" });
  }, [listaMensagens]);

  function enviarMensagemTemplate(mensagemInicial) {
    const dadosEmail = { 
      id_atendimento: atendimentoAtivo.id, 
      body: mensagemInicial.mensagem, 
      template: true, 
      id_template:mensagemInicial.id_template
    }
    trackPromise(caAPI.atendimento.email.enviar(dadosEmail))
      .then(res => {
        const novaMensagem = res.data
        setListaMensagens((messages) => [novaMensagem, ...messages]);
        setIniciado(true);
        setModalTemplateAberto(false)
      }).catch(err => {
        console.log(err)
        console.log(err.response)
        if(err.response?.status === 422) {
          Swal.fire('Ooops...', err.response?.data?.message, 'warning')

          return
        }
        Swal.fire({
          titleText: "Erro",
          text: "Ocorreu um problema ao iniciar o atendimento.",
          icon: 'error'
        });
      })
  }

  return (
    <>
      <div className="email-container" id="scrollableDiv">
        {(!iniciado && <Templates enviarMensagemTemplate={enviarMensagemTemplate}/>) || <></>}
        <InfiniteScroll
          className="email-container"
          style={{ display: "flex", flexDirection: "column-reverse" }}
          dataLength={mensagens?.length}
          next={handleLoadMoreMessages}
          hasMore={hasMore}
          inverse={true}
          loader={<Spinner animation="border" style={{ color: "#265d9c" }} />}
          endMessage={
            <p style={{ textAlign: "center" }}>
              <b>Fim das Mensagens</b>
            </p>
          }
          refreshFunction={() => { }}
          pullDownToRefresh
          pullDownToRefreshThreshold={50}
          scrollableTarget="scrollableDiv"
        >
          {mensagens}
        </InfiniteScroll>
      </div>
      <div ref={messageContainerRef}></div>
      <div className="footer">
        <Row>
          <Col>
            <Button className="btnEnviarEmail" onClick={() => setOpenModalEmail(true)}>
              <Icon icon={mailSendLine} fontSize={20} style={{ position: 'relative', top: '-1' }}/> Enviar Email
            </Button>
          </Col>
          {(iniciado && (
            <Col>
              <Button variant="info" className="btnEnviarTemplate" onClick={() => setModalTemplateAberto(true)}>
                <FontAwesomeIcon icon={faLayerGroup}/> Enviar Template
              </Button>
            </Col>
          )) || <></>}
        </Row>
      </div>
      <ModalDommus
        titulo="Enviar Email"
        open={openModalEmail}
        close={() => setOpenModalEmail(false)}
      >
        <ModalEmail
          assinatura={assinatura}
          AtualizarListaEmails={AtualizarListaEmails}
          setOpenModalEmail={setOpenModalEmail}
          atendimento={atendimento}
          assunto={JSON.parse(listaMensagens?.[0]?.detalhes || 'null')?.data?.assunto}
        />
      </ModalDommus>
      <ModalDommus
        titulo="Enviar Email Template"
        open={modalTemplateAberto}
        close={() => setModalTemplateAberto(false)}
      >
        <div className="templates-scrolaveis px-3">
          <Row className="px-3">
            <Col className="p-3 rounded border" style={{ backgroundColor: '#F2F3F8' }}>
              <Templates enviarMensagemTemplate={enviarMensagemTemplate}/>
            </Col>
          </Row>
          <Row>
            <Col className="p-3 text-right">
              <Button variant="secondary" onClick={() => setModalTemplateAberto(false)}>
                <FontAwesomeIcon icon={faArrowAltCircleLeft}/> Voltar
              </Button>
            </Col>
          </Row>
        </div>
      </ModalDommus>
      <ModalDommus
        titulo={
          <div className="header-visualizar-arquivo">
            <span>Visualizar arquivo</span> 
            <OverlayTrigger
              key={"file"}
              placement="bottom"
              overlay={
                <Tooltip id={`tooltip-download`}>
                  Baixar anexo
                </Tooltip>
              }
            >
              <FontAwesomeIcon 
                role="button" 
                icon={faDownload} 
                className="btn-download-file" 
                onClick={()=>{
                  handleDownloadAttachment(anexoSelecionadoVisualizacao)
                }}/>
            </OverlayTrigger>
          </div>
        }
        open={!!anexoSelecionadoVisualizacao}
        close={() => {
          setAnexoCarregado(false);
          setAnexoSelecionadoVisualizacao(null)
        }}
      >
        <div className="mail-modal-anexos">
          {['zip'].includes(anexoSelecionadoVisualizacao?.extension) ? 
            <Alert className="text-center" style={{cursor: 'pointer'}} variant="info" onClick={()=>{handleDownloadAttachment(anexoSelecionadoVisualizacao)}}>Não é possível carregar arquivos!. <b>Clique aqui para fazer o download</b></Alert>
          :
            <div className="documento-viewer-container">
              <DocumentViewer
                style={{visibility: anexoCarregado ? 'visible' : 'hidden', width: '100%', height: '100%'}}
                queryParams="hl=Nl"
                url={anexoSelecionadoVisualizacao?.url ?? ""}
                viewer={anexoSelecionadoVisualizacao?.typeViewer}
                viewerUrl={anexoSelecionadoVisualizacao?.extension}
                loaded={()=>{setAnexoCarregado(true)}}
              />
              {!anexoCarregado &&  <DommusLoading/>}
            </div>
          }
        </div>
      </ModalDommus>
      
    </>
  );
}
