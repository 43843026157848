
import React, { useEffect, useState } from "react";
import { Controller, useFormContext } from "react-hook-form";
import Select from "react-select";
import { Form, InputGroup } from "react-bootstrap";
import moment from "moment";
import { errorToast } from "../DommusToast";
import IntervaloDatasAgrupadosGrafico from "./IntervaloDatasAgrupadosGrafico";
import api from "../../services/api";

export function CampoFiltrosGlobaisDashboardDinamico({ configuracao }) {
    const [opcoes, setOpcoes] = useState([])
    const methods = useFormContext();
    
    useEffect(() => {
        if(configuracao.endpoint) {
            api.get(configuracao.endpoint)
                .then(response => montarOpcoesDinamicas(response.data))
                .catch(error => {
                    console.log(error)
                    console.log(error?.response)
                    errorToast.fire({ text: 'Houve um erro ao buscar as opções do filtro.' })
                })
        } else if(configuracao.opcoes) {
            setOpcoes(configuracao.opcoes)
        } else if(configuracao.nomeCampo) {
        }
    }, [])

    function montarOpcoesDinamicas(opcoes) {
        const opcoesGeradas = opcoes.map((opcao) => {
            return {
                label: opcao[configuracao.opcoes?.nome],
                value: opcao[configuracao.opcoes?.valor],
            };
        })
        setOpcoes(opcoesGeradas)
    }

    function montarCampo() {
        switch(configuracao?.tipoCampo) {
            case 'multiselect':
                return (
                    <Controller
                        name={configuracao?.nomeCampo}
                        control={methods.control}
                        render={({ field }) => <Select
                            {...field}
                            isMulti
                            placeholder="Selecionar"
                            options={opcoes ?? []}
                            className="basic-multi-select"
                            classNamePrefix="select"
                            menuPosition="fixed"
                          />
                        }
                    />
                )
            case 'data-periodo':
                let today = null;
                let pastDate = null;
                if(configuracao?.data?.periodoPadrao && Number(configuracao?.data?.periodoPadrao) > 0){
                    today = moment().format("YYYY-MM-DD");
                    pastDate = moment().subtract(configuracao?.data?.periodoPadrao, "days").format("YYYY-MM-DD");
                }
                return (
                    <InputGroup style={{ flexWrap: "nowrap" }}>
                        <Form.Control 
                            className="spaceData" 
                            type="date" 
                            defaultValue={pastDate ?? moment().subtract(3, 'months').date(1).format('YYYY-MM-DD')} 
                            {...methods.register(configuracao?.data?.inicial)}
                        />
                        <InputGroup.Append style={{ marginLeft: -3, width: "45%" }}>
                            <Form.Control className="spaceData"  type="date" defaultValue={today ?? moment().format('YYYY-MM-DD')} {...methods.register(configuracao?.data?.final)}/>
                        </InputGroup.Append>
                    </InputGroup>
                )
            case 'data':
                return (
                    <InputGroup style={{ flexWrap: "nowrap" }}>
                        <Form.Control 
                            className="spaceData" 
                            type="date" 
                            {...methods.register(configuracao?.data?.inicial)}
                        />
                        <InputGroup.Append style={{ marginLeft: -3, width: "45%" }}>
                            <Form.Control className="spaceData"  type="date" {...methods.register(configuracao?.data?.final)}/>
                        </InputGroup.Append>
                    </InputGroup>
                )
            case 'periodo-agrupados':
                return (configuracao?.campos && configuracao.campos.length ? 
                    <IntervaloDatasAgrupadosGrafico campos={configuracao?.campos} configuracao={configuracao}/>
                    :<></>
                );
            default:
                return <Form.Control type={configuracao.tipoCampo} {...methods.register(configuracao?.nomeCampo)}/>
        }
    }
        
    return montarCampo()
}
