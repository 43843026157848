import { faDownload, faEye, faFile, faFileExcel, faFilePdf, faFileWord } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useMemo, useState } from "react";
import { Image } from "react-bootstrap";
import "./index.css";
import urlHelper from "../../helpers/UrlHelper";
import { isMobile } from "react-device-detect";
import AnexoIcone from "../Anexos/AnexoIcone";

export function DommusThumbnail({ url, fileName, extensao=null, handlePreviewFile=null, arquivo=null }) {
    
    const extension = useMemo(()=>{
      let extension = "";
      if(!extensao){
        let arrayInfo = url.split('.');
        if (arrayInfo.length) {
            extension = arrayInfo.at(-1).toLocaleLowerCase();
        }
      }else{
        const extsplit = extensao.split('/');
        extension = extsplit.length > 1 ? extsplit.at(-1)?.toLocaleLowerCase() : extensao;
        switch(extension){
          case 'vnd.openxmlformats-officedocument.spreadsheetml.sheet':
            extension = 'xlsx';
            break;
          case 'vnd.openxmlformats-officedocument.wordprocessingml.document':
            extension = 'docx';
            break;
          default:
            break;
        }
      }

      return extension;
    },[extensao])
    

    const fileType = useMemo(() => {
      switch (extension) {
          case 'png':
          case 'jpg':
          case 'jpeg':
          case 'svg':
              return "image";
          case 'pdf':
              return "pdf";
          case 'docx':
          case 'doc':
              return 'word';
          case 'xlsx':
          case 'xlsm': 
              return 'excel';
          default:
              return 'file';
      }
    }, [url, extension]);

    const handleClickFile = () => {
        url = urlHelper.alterarUrlArquivoComNomeOriginal(url, arquivo?.original_name, arquivo?.file_name)
      if(handlePreviewFile){
        handlePreviewFile(url, extension)
      }else{
        urlHelper.download(url);
      }
    }

    const mountImage = () => {
        return (   
            <div className="thumb img-file" data-tip={fileName}>
                <Image src={url} rounded onClick={() => { handleClickFile() }} />
            </div>         
        );
    }


    const mountPdf = () => {
        let arrayInfo = url.split('.');
        let extension = "";
        if (arrayInfo.length) {
            extension = arrayInfo[arrayInfo.length - 1].toLocaleLowerCase();
        }
        return (
            <div className="thumb pdf-file" >
                {isMobile ?
                    <div className="pdf-thumb">
                        <AnexoIcone anexo={extension} style={{ fontSize: "3.5rem" }} />
                    </div>
                :
                    <object data={url} type="application/pdf">
                        <p>Arquivo em PDF - <a href={url}>to the PDF!</a></p>
                    </object>
                }
                <div className="layer-download" onClick={() => { handleClickFile() }}>
                    <FontAwesomeIcon icon={handlePreviewFile ? faEye : faDownload} color="white"/>
                </div>
            </div>
        );
    }
    const mountWord = () => {
        return (
            <div className="thumb file-background" style={{ background: "blue" }} onClick={() => { handleClickFile() }}>
                <FontAwesomeIcon icon={faFileWord} color="white" />
            </div>
        );
    }
    const mountExcel = () => {
        return (
            <div className="thumb file-background" style={{ background: "green" }} onClick={() => { handleClickFile() }}>
                <FontAwesomeIcon icon={faFileExcel} color="white" />
            </div>
        );
    }
    const mountFile = () => {
        return (
            <div className="thumb file-background" style={{ background: "grey" }} onClick={() => { handleClickFile() }}>
                <FontAwesomeIcon icon={faFile} color="white" />
            </div>
        );
    }

    const mountThumbnail = (type) => {
        switch (type) {
            case 'image':
                return mountImage();
            case 'pdf':
                return mountPdf();
            case 'word':
                return mountWord();
            case 'excel':
                return mountExcel();
            default:
                return mountFile();
        }
    }

    return (mountThumbnail(fileType));
}
