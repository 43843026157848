
import React, { useMemo, useCallback, useEffect, useRef, useState, useContext, Fragment } from 'react';
import { Tab, Col, Nav, Table, OverlayTrigger, Tooltip, Alert } from 'react-bootstrap';
import moment from "moment";
import Swal from 'sweetalert2';
import { trackPromise } from "react-promise-tracker";
import './style.scss';
import { AtendimentoContext } from '../../contexts/AtendimentoContext';
import caAPI from '../../services/caAPI';
import Anotacao from '../Anotacoes/anotacao';
import { InputMensagens } from '../InputMensage';
const disabledMenu = true;

export default function AnotacoesPresencial({ handleUpdateListaAnotacoes, showAtendimentosDoDia}) {
    const [listaMensagens, setListaMensagens] = useState();
    const [mensagemInput, setMensagemInput] = useState('');
    const messageContainerRef = useRef();
    const {atendimentoAtivo} = useContext(AtendimentoContext);

    useEffect(() => {
      trackPromise(
          caAPI.atendimento.anotacao.list(atendimentoAtivo.id).then(res => {
            const listaNova = ( res.data && Array.isArray(res.data) && res.data ) || [];
            console.log(atendimentoAtivo.detalhes, listaNova);
            setListaMensagens(listaNova);
          }).catch(e => {
              Swal.fire({
                  titleText: "Erro",
                  text: "Houve um problema ao enviar a mensagem " + e,
                  icon: 'error'
              });
          })
      )
    }, [atendimentoAtivo]);

    useEffect(() => {
        if (handleUpdateListaAnotacoes instanceof Function) {
            handleUpdateListaAnotacoes(listaMensagens)
        }
    }, [listaMensagens, handleUpdateListaAnotacoes]);

    const atualizaAnotacao = useCallback(({ mensagem, id }) => {
        trackPromise(
            caAPI.atendimento.anotacao.atualizar(atendimentoAtivo.id, id, mensagem).then(res => {
            }).catch(e => {
                Swal.fire({
                    titleText: "Erro",
                    text: "Houve um problema ao enviar a mensagem " + e,
                    icon: 'error'
                });
            })
        )
    }, [atendimentoAtivo]);

    const removeAnotacao = useCallback((id_anotacao) => {
        trackPromise(
            caAPI.atendimento.anotacao.deletar(atendimentoAtivo.id, id_anotacao).then(res => {
                setListaMensagens(res.data)
            }).catch(e => {
                Swal.fire({
                    titleText: "Erro",
                    text: "Houve um problema ao enviar a mensagem " + e,
                    icon: 'error'
                });
            })
        )
    }, [atendimentoAtivo]);


    const mensagens = useMemo(() => {
        if (listaMensagens && Array.isArray(listaMensagens) && listaMensagens?.length) {
            return listaMensagens.map(msg => {
                return <div key={msg.id}>
                    <Anotacao mensagem={msg} atualizaAnotacao={atualizaAnotacao.bind(this)} removeLista={removeAnotacao.bind(this)} />
                </div>
            });
        }else{
            return <Alert variant='info'>Nenhuma anotação encontrada</Alert>
        }
    }, [listaMensagens, atualizaAnotacao, removeAnotacao]);

    function recebeMensagemInput(e) {
        setMensagemInput(e);
    }

    const enviarMensagem = useCallback(() => {
        trackPromise(
            caAPI.atendimento.anotacao.enviar(atendimentoAtivo.id, mensagemInput).then(res => {
                setListaMensagens(res.data);
            }).catch(e => {
                Swal.fire({
                    titleText: "Erro",
                    text: "Houve um problema ao enviar a mensagem " + e,
                    icon: 'error'
                });
            })
        )
        setMensagemInput('');
    }, [atendimentoAtivo, mensagemInput]);

    const changeSubmitKey = useCallback(e => {
        if (e.key === 'Enter' && !e.shiftKey) {
            e.preventDefault();
            enviarMensagem();
        }
    }, [enviarMensagem]);

    return <>
        <div id='anotacoes-presencial'>
          <div className='conteudo'>
                <div className="anotacoesContainer">
                    {mensagens}
                    <div ref={messageContainerRef}></div>
                </div>
                <InputMensagens
                    disabledMenu={disabledMenu}
                    mensagemInput={mensagemInput}
                    enviarMensagem={enviarMensagem}
                    receberMensagemInicial={enviarMensagem}
                    atendimento={atendimentoAtivo}
                    recebeMensagemInput={recebeMensagemInput}
                    changeSubmitKey={changeSubmitKey} />
            </div>
        </div>
    </>
}
