import api from "./api";

export const EmpreendimentoService = {
  index: () => {
    return api.get(`empreendimento`);
  },
  buscarUnidadesPorEmpreendimento: (idEmpreendimento) => {
    return api.get(`empreendimento/${idEmpreendimento}/unidades`);
  },
};
