import React, { useContext, useEffect, useState } from "react";
import { Button, Col, Container, Form, OverlayTrigger, Row, Tooltip, InputGroup } from "react-bootstrap";
import DommusBarraTitulo from "../../components/DommusBarraTitulo";
import ItemBarraTitulo from "../../components/DommusBarraTitulo/ItemBarraTitulo";
import DommusToggle from "../../components/DommusToggle";
import { CartaoDashboardAtendente } from "../../components/CartaoDashboardAtendente";
import './index.css'
import { DashboardService } from "../../services/DashboardService"
import Swal from "sweetalert2";
import { DommusAlertaBootstrap } from "../../components/DommusAlertaBootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFilter, faSearch } from "@fortawesome/free-solid-svg-icons";
import { trackPromise } from "react-promise-tracker";
import calendarOutlineBadged from '@iconify-icons/clarity/calendar-outline-badged';
import DatePicker from "react-date-picker/dist/entry";
import "../PortalCliente/AtendimentoIntervaloDatasPicker/DatePicker.scss";
import { Icon } from "@iconify/react";
import ModalDommus from "../../components/Modal/index";
import { Controller, useForm } from "react-hook-form";
import Select from "react-select";
import { TipoAtendimentoContext } from "../../contexts/TipoAtendimentoContext";
import { EmpreendimentoContext } from "../../contexts/EmpreendimentosContext";
import caAPI from "../../services/caAPI";

export function Dashboard() {
  const [usuarios, setUsuarios] = useState(null)
  const [dataInicial, setDataInicial] = useState(new Date())
  const [dataFinal, setDataFinal] = useState(new Date())
  const [atualizarDados, setAtualizarDados] = useState(false)
  const [modalFiltrosAberto, setModalFiltrosAberto] = useState(false)
  const { register, handleSubmit, control, setValue } = useForm()
  const { tiposAtendimento } = useContext(TipoAtendimentoContext)
  const { empreendimentoLista } = useContext(EmpreendimentoContext)
  const [timeoutAtualizar, setTimeoutAtualizar] = useState(null);

  const atualizarUsuarios = () => {
    
    if(timeoutAtualizar){
      clearTimeout(timeoutAtualizar);
      setTimeoutAtualizar(null);
    }
    
    let filtrosAtuais = caAPI.getLocalState('filtrosDashboard');
    if(!filtrosAtuais){
      const novosFiltros = {
        dataFinal: dataFinal,
        dataInicial: dataInicial
      }
      caAPI.setLocalState('filtrosDashboard', novosFiltros)
    }
    trackPromise(
      DashboardService.buscarListaUsuarios()
    ).then(res => {
        const novoUsuarios = res.data;
        setUsuarios(novoUsuarios)
        const filtros = caAPI.getLocalState('filtrosDashboard')
        if(filtros?.dataFinal) {
          setDataFinal(filtros.dataFinal.includes('T') ? filtros.dataFinal : `${filtros.dataFinal}T03:00:00Z`)
        }
        if(filtros?.dataInicial) {
          setDataInicial(new Date(filtros.dataInicial.includes('T') ? filtros.dataInicial : `${filtros.dataInicial}T03:00:00Z`))
        }
        const novoTimeout = setTimeout(() => {
          atualizarUsuarios();
        }, 600000);
        setTimeoutAtualizar(novoTimeout);
      }).catch(error => {
        const novoTimeout = setTimeout(() => {
          atualizarUsuarios();
        }, 600000);
        setTimeoutAtualizar(novoTimeout);
        Swal.fire('Ooops...', `Houve um erro ao buscar os usuários. Erro: ${error}, Detalhes: ${error.response?.data}`, 'error')
        
      })
  }

  function atualizarFiltros(dados) {
    setModalFiltrosAberto(false)
    const filtros = {
      empreendimentos: dados.empreendimento?.map(empreendimento => empreendimento.value) || null,
      tipoAtendimentos: dados.tipoAtendimento?.map(tipoAtendimento => tipoAtendimento.value) || null,
      dataFinal: dados.dataFinal || dataFinal,
      dataInicial: dados.dataInicial || dataInicial
    }
    caAPI.setLocalState('filtrosDashboard', filtros)
    if(dados.dataFinal) {
      setDataFinal(new Date(`${dados.dataFinal}T03:00:00Z`))
    }
    if(dados.dataInicial) {
      setDataInicial(new Date(`${dados.dataInicial}T03:00:00Z`))
    }
    atualizarUsuarios();
  }

  function montaOpcoes(recursos, descricao, valor) {
    return recursos.map(recurso => {
      return {
        label: recurso[descricao],
        value: recurso[valor]
      }
    })
  }

  function alterarPeriodo(data, setState, atributoFiltro) {
    if((atributoFiltro == 'dataInicial' && data > dataFinal) || (atributoFiltro == 'dataFinal' && data < dataInicial)) {
      Swal.fire('Período Inválido!', 'A data inicial deve ser anterior a data final.', 'warning')

      return
    }
    const filtrosAtualizados = Object.assign(caAPI.getLocalState('filtrosDashboard') || {}, { [atributoFiltro]: data.toISOString()})
    caAPI.setLocalState('filtrosDashboard', filtrosAtualizados)
    setState(data)
    setValue(atributoFiltro, data)
    atualizarUsuarios();
  }

  function montaValorPadrao(recursos, label, value, nomeRecursoFiltro) {
    const filtrosPadroes = caAPI.getLocalState('filtrosDashboard')
    if(!filtrosPadroes || !filtrosPadroes[nomeRecursoFiltro]) {
      return []
    }
    const recursosFiltrados = recursos.map(recurso => {
      recurso[value] = Number(recurso[value])

      return recurso
    }).filter(recurso => filtrosPadroes[nomeRecursoFiltro].map(filtro => Number(filtro)).includes(Number(recurso[value])))
    return montaOpcoes(recursosFiltrados, label, value)
  }

  useEffect(() => {
    if(!(usuarios)){
      atualizarUsuarios();
    }
  }, [usuarios])

  return (<Container fluid>
      <DommusBarraTitulo>
        <ItemBarraTitulo>Dashboard</ItemBarraTitulo>
        <ItemBarraTitulo>
          <DatePicker
            onChange={(data) => alterarPeriodo(data, setDataInicial, 'dataInicial')}
            value={dataInicial}
            format="dd/MM/yyyy"
            calendarIcon={<Icon icon={calendarOutlineBadged} width="20" style={{ color: '#f58442' }}/>}
            clearIcon={null}
          /> 
          <DatePicker
            onChange={(data) => alterarPeriodo(data, setDataFinal, 'dataFinal')}
            value={dataFinal}
            format="dd/MM/yyyy"
            calendarIcon={<Icon icon={calendarOutlineBadged} width="20" style={{ color: '#f58442' }}/>}
            clearIcon={null}
          />
        </ItemBarraTitulo>
        <OverlayTrigger key="pesquisar-dashboard" placement="top" overlay={<Tooltip id={`tooltip-pesquisa-dashboard`}>Pesquisar</Tooltip>}>
          <ItemBarraTitulo classe="btn" onClick={() => setModalFiltrosAberto(true)}>
            <FontAwesomeIcon icon={faSearch}  color="#265d9c" />
          </ItemBarraTitulo>
        </OverlayTrigger>
      </DommusBarraTitulo>
      { usuarios && usuarios.length ? 
        <DommusToggle toggle={true} title="Atendentes">
            <div className="grid-cards mb-0 px-0">
                {usuarios.map((usuario, idx) => <CartaoDashboardAtendente key={`${usuario}-${idx}`} usuario={usuario}/>)}
            </div>
        </DommusToggle> : <DommusAlertaBootstrap mensagem="Nenhum atendente encontrado."/>}
      <ModalDommus open={modalFiltrosAberto} close={() => setModalFiltrosAberto(false)} size="lg" titulo="Filtrar Atendimentos" classe={'modal-filtros-dashboard'}>
        <Form onSubmit={handleSubmit(atualizarFiltros)} className="pt-3">
          <Row className="mb-3">
            <Col>
              <Form.Label>Última movimentação do atendimento</Form.Label>
              <InputGroup className="mb-3">
                  <Form.Control type="date" {...register('dataInicial')}/>
                  <Form.Control type="date" {...register('dataFinal')}/>
              </InputGroup>
            </Col>
          </Row>
          <Row>
            <Form.Group as={Col}>
              <Form.Label>Tipo de Atendimento</Form.Label>
              <Controller
                name="tipoAtendimento"
                control={control}
                defaultValue={montaValorPadrao(tiposAtendimento, 'descricao', 'id', 'tipoAtendimentos')}
                render={({ field }) => <Select
                    {...field}
                    isMulti
                    placeholder="Selecionar"
                    name="tipoAtendimento"
                    options={montaOpcoes(tiposAtendimento, 'descricao', 'id')}
                    className="basic-multi-select"
                    classNamePrefix="select"
                    menuPosition="fixed"
                />}
              />
            </Form.Group>
            <Form.Group as={Col}>
              <Form.Label>Empreendimento</Form.Label>
              <Controller
                name="empreendimento"
                control={control}
                defaultValue={montaValorPadrao(empreendimentoLista, 'nome', 'id', 'empreendimentos')}
                render={({ field }) => <Select
                    {...field}
                    isMulti
                    placeholder="Selecionar"
                    name="empreendimento"
                    options={montaOpcoes(empreendimentoLista, 'nome', 'id')}
                    className="basic-multi-select"
                    classNamePrefix="select"
                    menuPosition="fixed"
                />}
              />
            </Form.Group>
          </Row>
          <Row className="formFooter">
            <Col>
              <Button type="submit" className="float-right btn-success"><FontAwesomeIcon icon={faFilter}/> Filtrar</Button>
            </Col>
          </Row>
        </Form>
      </ModalDommus>
    </Container>)
}
