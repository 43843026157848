import React, { useState, useCallback, useEffect, useRef, useContext } from 'react';
import { Form, Button, InputGroup, Dropdown, DropdownButton, Alert, Col, Row, Tab, Nav, Container } from "react-bootstrap";
import { Icon } from '@iconify/react';
import sendOutlined from '@iconify-icons/ant-design/send-outlined';
import mailTemplate24Regular from '@iconify-icons/fluent/mail-template-24-regular';
import overflowMenuVertical from '@iconify-icons/carbon/overflow-menu-vertical';
import outlineNoteAlt from '@iconify-icons/ic/outline-note-alt';
import paperclipIcon from '@iconify-icons/mdi/paperclip';
import Picker from 'emoji-picker-react';
// npm install --save-dev @iconify/react @iconify-icons/emojione
import slightlySmilingFace from '@iconify-icons/emojione/slightly-smiling-face';
import useArrowKeyNavigationHook from "react-arrow-key-navigation-hook";
import ModalTemplate from "../../pages/PortalCliente/DetalheAtendimento/ModalTemplate";
import ModalDommus from "../Modal";
import Anotacoes from "../Anotacoes";
import Upload from "./Upload/upload"
import './style.scss';
import caAPI from '../../services/caAPI';
import { errorToast, successToast } from '../DommusToast';
import Swal from 'sweetalert2';
import { AtendimentoContext } from '../../contexts/AtendimentoContext';
import { ItensAtalhoContext } from '../../contexts/ItensAtalhosContext';
import { AtalhoSection } from './AtalhoSection';
import { AtendentesContext } from '../../contexts/AtendentesContext';
import { Galeria } from '../Galeria';
import {isBrowser, MobileView, BrowserView} from 'react-device-detect';

export const InputMensagens = ({
    setLegendaInput,
    templateMensagens,
    disabledInputMessage,
    disabledTemplates,
    disabledAnotacoes,
    disabledEmoticons,
    disabledAnexos,
    disabledMenu,
    recebeMensagemInput,
    recebeArquivo,
    enviarMensagem,
    receberMensagemInicial,
    enviarImagem,
    atendimento,
    changeSubmitKey,
    applyRule = true,
    }) => {

    const [openModalAnotacoes, setOpenModalAnotacoes] = useState(false);
    const [openModalUpload, setOpenModalUpload] = useState(false);
    const [openModalTemplates, setOpenModalTemplates] = useState(false);
    const [mensagemInput, setMensagemInput] = useState(' ');
    const [openSectionAtalho, setOpenSectionAtalho] = useState(false);
    const [listaItensCategorias, setListaItensCategorias] = useState([]);
    const [listaItensFiltrada, setListaItensFiltrada] = useState([]);
    const [listaItens, setListaItens] = useState([]);
    const [atalhoSelecao, setAtalhoSelecao] = useState(1);
    const [categoriaSelecionada, setCategoriaSelecionada] = useState(null);
    const parentRef = useArrowKeyNavigationHook({ selectors: "a,input" });
    const referenceComponent = useRef(null);
    const {handleReloadAtendimento, handleAdiconaLista} = useContext(AtendimentoContext);
    const {ItensAtalhoLista} = useContext(ItensAtalhoContext);
    const {testarUsuarioAtendeInputMensagem} = useContext(AtendentesContext);
    const {atendimentoAtivo} = useContext(AtendimentoContext);
    const listaCategorias = [
        {label: 'Mensagens', tipo: 'mensagens', icone: mailTemplate24Regular, color: "#4caf50"},
        {label: 'Tags', tipo: 'tags', icone: "tabler:tags", color: "#f1a042"},
        {label: 'Status do Atendimento', tipo: 'statusDoAtendimento', icone: "material-symbols:change-circle-outline", color: "#f44336" },
        {label: 'Tipos de Atendimento', tipo: 'tipoAtendimento', icone: "material-symbols:perm-phone-msg-outline-rounded", color: "#3689ff"},
    ]

    const onEmojiClick = (event, emojiObject) => {
        setMensagemInput(mensagemInput + emojiObject.emoji);
        recebeMensagemInput(mensagemInput + emojiObject.emoji);
    };

    useEffect(()=>{

        let metodo = caAPI.meio_comunicacao.getTemplate;
        if(templateMensagens){
            if(templateMensagens === 'whatsapp'){
                metodo = caAPI.meio_comunicacao.getTemplatesAprovados;
            }
            metodo(templateMensagens).then(res => {
                criarListaItensCategorias(res.data);
            }).catch(err => {
                Swal.fire({
                    titleText: "Erro",
                    text: "Houve um problema ao buscar os meios de comunicação  " + err,
                    icon: 'error'
                });
            });
        }else{
            criarListaItensCategorias(null);
        }
    // eslint-disable-next-line no-use-before-define
    },[templateMensagens])

    useEffect(()=>{
        handleSectionAtalho();
    },[listaItensFiltrada, atalhoSelecao])


    const criarListaItensCategorias = (mensagensLista)=>{
        let listaTemp = [];
        if(mensagensLista){
            listaTemp = mensagensLista.map((mensagem)=>{
                return {
                    id: mensagem.id,
                    nome: mensagem.nome,
                    categoria: "mensagens",
                    mensagem: mensagem.mensagem,
                };
            });
        }
        listaTemp = listaTemp.concat(ItensAtalhoLista);
        setListaItensCategorias(listaTemp);
        setListaItensFiltrada(listaTemp);
    }

    const filtrarLista = (lista, query)=>{
        query = query.replace('/','');
        if(!query || query === '' ) return lista;
        return lista.filter((item)=>{
            const itemNome = item.nome.toLowerCase();
            return itemNome.includes(query.toLowerCase());
        });
    }

    const filtrarListaCategorias = (lista, categoria)=>{
        return lista.filter((item)=>{
            return item.categoria === categoria;
        })
    }

    const handleSelecionarItem = (tipo, item)=>{
        let data = {}

        switch (tipo) {
            case 'statusDoAtendimento':
                data = {
                    "situacao": item.id,
                }
                updateAtendimento(data);
                break;
            case 'tipoAtendimento':
                data = {
                    "tipo_atendimento": item.id,
                }
                updateAtendimento(data);
                break;
            case 'tags':
                data = {
                    "tags":[
                        {
                            "id_tag": item.id,
                            "nome": item.nome,
                        }
                    ]
                }
                updateAtendimento(data)
                break;
            case 'mensagens':
                setMensagemInput(item.mensagem);
                recebeMensagemInput(item.mensagem.replace(/(<([^>]+)>)/ig, ''));
                referenceComponent.current.focus();
                break;
            default:
                break;
        }
        setOpenSectionAtalho(false);
    }

    const updateAtendimento = (data)=>{
        caAPI.atendimento.update(data, atendimento.id).then(
            (response)=>{
                successToast.fire("Atendimento atualizado com sucesso!!");
                handleReloadAtendimento();
                handleAdiconaLista(response.data);
                setMensagemInput('');
                setListaItensFiltrada(listaItensCategorias);
                referenceComponent.current.focus();
        }).catch((error)=>{
                errorToast.fire("Erro ao atualizar atendimento!!" + error);
        })
    }

    const handleSelecionarCategoria = (categoria)=>{
        setMensagemInput('/')
        setListaItensFiltrada(filtrarListaCategorias(listaItensCategorias, categoria.tipo))
        setCategoriaSelecionada(categoria);
        handleOpenAtalho(1);
    }

    const createElementoItensComCategoria = ()=>{
        let elementsReturn = [];
        if(categoriaSelecionada === null){
            listaCategorias.forEach((categoria, index)=>{
                let listaFiltrada = filtrarListaCategorias(listaItensFiltrada, categoria.tipo);
                    if(listaFiltrada.length !== 0){
                        elementsReturn.push(
                            <div key={index}>
                                <div className="header-atalho">{categoria.label}</div>
                                <div className="itens-atalho">
                                {
                                    listaFiltrada.map((item)=>{
                                        return (
                                            categoria.tipo === "mensagens" ?
                                            // eslint-disable-next-line jsx-a11y/anchor-is-valid
                                            <a
                                            key={item.id}
                                            className="item-atalho"
                                            href="#"
                                            onClick={(e) => {handleSelecionarItem(categoria.tipo, item)}}
                                            >
                                                <Row>
                                                    <Col md={1}>
                                                        <Icon icon={categoria.icone} className="icon-item-atalho" style={{color: categoria.color}}/>
                                                    </Col>
                                                    <Col>
                                                        <Row>
                                                            {item.nome}
                                                        </Row>
                                                        <Row className='conteudo-mensagem-item'>
                                                            {item.mensagem.replace(/(<([^>]+)>)/ig, '')}
                                                        </Row>
                                                    </Col>
                                                </Row>
                                            </a>
                                        :
                                            // eslint-disable-next-line jsx-a11y/anchor-is-valid
                                            <a
                                            key={item.id}
                                            className="item-atalho"
                                            href="#"
                                            onClick={(e) => {handleSelecionarItem(categoria.tipo, item)}}
                                            >
                                                <Icon icon={categoria.icone} className="icon-item-atalho" style={{color: categoria.color}}/>
                                                {item.nome}
                                            </a>
                                        )
                                    })
                                }
                                </div>
                            </div>
                        )
                    }
                })
        }else{
            elementsReturn.push(
                <div key={categoriaSelecionada.label}>
                    <div className="header-atalho">{categoriaSelecionada.label}</div>
                    <div className="itens-atalho">
                    {
                        listaItensFiltrada.filter((item)=>{ return item.categoria === categoriaSelecionada.tipo}).map((item)=>{
                            return (
                                // eslint-disable-next-line jsx-a11y/anchor-is-valid
                                <a
                                key={item.id}
                                className="item-atalho"
                                href="#"
                                onClick={() => {handleSelecionarItem(categoriaSelecionada.tipo, item)}}
                                >
                                    <Icon icon={categoriaSelecionada.icone} className="icon-item-atalho" style={{color: categoriaSelecionada.color}}/>
                                    {item.nome}
                                </a>
                                )
                            })
                        }
                    </div>
                </div>
            )
            setCategoriaSelecionada(null);
        }
        return elementsReturn.length !== 0 ?
                elementsReturn :
                <Alert variant={'warning'} className="alert-section">
                    <strong>Nenhum item encontrado!</strong>
                </Alert> ;
    }

    const createElementoCategorias = ()=>{
        return (
            <>
            <div className="header-atalho">Categoria</div>
            <div className="itens-atalho">
                {
                    listaCategorias.map((categoria, index)=>{
                        return(
                            // eslint-disable-next-line jsx-a11y/anchor-is-valid
                            <a
                            key={index}
                            className="item-atalho"
                            href="#"
                            onClick={()=>{handleSelecionarCategoria(categoria)}}
                            >
                                <Icon icon={categoria.icone} className="icon-item-atalho" style={{color: categoria.color}}/>
                                {categoria.label}
                            </a>
                        );
                    })
                }
            </div>
        </>
        );
    }

    const handleOpenAtalho = (tipo)=>{
        referenceComponent.current.focus();
        setAtalhoSelecao(tipo);
    }

    const handleSectionAtalho = ()=>{
        let tempItens = []
        switch(atalhoSelecao){
            case 1:
                tempItens = createElementoItensComCategoria();
                setListaItens(tempItens);
                break;
            case 2:
                tempItens = createElementoCategorias();
                setListaItens(tempItens);
                break;
            default:
                    break;
        }
    }

    const handleChangeMensagem = useCallback(e => {
        if(e.target.value === '/'){
            handleOpenAtalho(1)
            setOpenSectionAtalho(true);
        }else if(e.target.value === '//'){
            handleOpenAtalho(2)
        }else if(!e.target.value.includes('/') || e.target.value === '///'){
            setOpenSectionAtalho(false);
        }
        let filter = filtrarLista(listaItensCategorias, e.target.value);
        if(JSON.stringify(filter) !== JSON.stringify(listaItensFiltrada)){
            setListaItensFiltrada(filter);
        }
        setMensagemInput(e.target.value)
        recebeMensagemInput(e.target.value)
    }, [setMensagemInput, recebeMensagemInput]);

    function handleSubmitMessageWithKey(e) {
        if (e.key === 'Enter' && !e.shiftKey && mensagemInput) {
            setMensagemInput('');
            changeSubmitKey(e);
        }
    }

    const atualizarQuantidadeLinhasTextarea = () => {
        const textarea = referenceComponent.current;
        textarea.rows = 1
        const quantidadeLinhasTextearea = Math.ceil(textarea.scrollHeight / parseFloat(getComputedStyle(textarea).lineHeight));
        textarea.rows = quantidadeLinhasTextearea >= 6 ? 6 : quantidadeLinhasTextearea - 1;
    };

    const handleUpdateListaAnotacoes = (listaAnotacoes) => {
        if (atendimento.detalhes) {
            let anotacoesUpdate = typeof atendimento.detalhes === 'object'
                ? atendimento.detalhes
                : JSON.parse(atendimento.detalhes)
            atendimento.detalhes = anotacoesUpdate;
        }
    }

    useEffect(() => {
        if (!openModalUpload) {
            setMensagemInput('');
        }
    }, [openModalUpload]);


    return (<>
        <div className="inputMensages" ref={parentRef}>
            <InputGroup>
                <InputGroup.Prepend>
                    <DropdownButton
                        disabled={disabledMenu}
                        id="dropMenuOpcoes"
                        size="sm"
                        title={
                            <Icon icon={overflowMenuVertical} />
                        }>
                        {disabledAnotacoes ? <></> :
                            <>
                                <Dropdown.Item
                                    className="opcaoMenu"
                                    onClick={() => { setOpenModalAnotacoes(true) }}>
                                    <div className="opcaoAnotacoes">
                                        <Icon icon={outlineNoteAlt} width="1.3rem" />
                                        <span className="tituloAnotacoes">
                                            Anotações
                                        </span>
                                    </div>
                                </Dropdown.Item>
                                <Dropdown.Divider />
                            </>
                        }
                        {disabledAnexos ? <></> :
                            <>
                                <Dropdown.Item
                                    className="opcaoMenu"
                                    onClick={() => { setOpenModalUpload(true) }}>
                                    <div className="opcaoEnviarImagem">
                                        <Icon icon={paperclipIcon} width="1.3rem" /><span className="tituloEnviarImagem">Imagens & Anexos</span>
                                    </div>
                                </Dropdown.Item>
                                <Dropdown.Divider />
                            </>
                        }
                        {disabledTemplates ? <></> :
                            <>
                                <Dropdown.Item
                                    className="opcaoMenu"
                                    onClick={() => { setOpenModalTemplates(true) }}>
                                    <div className="opcaoTemplate">
                                        <Icon icon={mailTemplate24Regular} width="1.3rem" />
                                        <span className="tituloTemplate">
                                            Selecionar mensagens
                                        </span>

                                    </div>
                                </Dropdown.Item>
                            </>
                        }
                    </DropdownButton>
                    <DropdownButton
                        disabled={disabledEmoticons}
                        drop='up'
                        id="dropEmoticon"
                        size="sm"
                        title={
                            <Icon icon={slightlySmilingFace} />
                        }
                    >
                        <Dropdown.Item className="listaEmoticon">
                            <Picker
                                groupNames={{
                                    smileys_people: "Smileys e Pessoas",
                                    animals_nature: "Animais e Natureza",
                                    food_drink: "Comidas e Bebidas",
                                    travel_places: "Viagem e Lugares",
                                    activities: "Atividaes",
                                    objects: "Objetos",
                                    symbols: "Simbolos",
                                    flags: "Bandeiras",
                                    recently_used: "Usados recentemente"
                                }}
                                native
                                disableSearchBar
                                disableSkinTonePicker
                                onEmojiClick={onEmojiClick} />
                        </Dropdown.Item>
                    </DropdownButton>
                    {/* <Icon icon={outlineNoteAlt} width="1.3rem" color="white" /> */}
                </InputGroup.Prepend>
                <Form.Control
                    disabled={
                        applyRule ?
                        testarUsuarioAtendeInputMensagem(atendimentoAtivo.atendente)
                        ?
                        false : disabledInputMessage
                        : disabledInputMessage
                    }
                    className="inputMensagem rounded"
                    aria-label="Mensagens"
                    onChange={e => {

                        if(mensagemInput.length <= 1600){
                            handleChangeMensagem(e)
                            atualizarQuantidadeLinhasTextarea()
                        }else{
                            Swal.fire({
                                icon:'info',
                                position:'top-right',
                                text: 'Mensagem muito extensa!',
                                timer:3000
                            })
                        }


                    }}
                    onKeyDown={e => {
                        if(!openSectionAtalho){
                            handleSubmitMessageWithKey(e)
                        }
                    }}
                    value={mensagemInput}
                    as="textarea"
                    autoFocus={true}
                    ref={referenceComponent}
                    rows={1}
                />
                <InputGroup.Append className="enviarMensagem">
                    <Button
                        disabled={disabledInputMessage}
                        onClick={() => {
                          if(mensagemInput){
                            enviarMensagem(mensagemInput)
                            setMensagemInput('')
                          }
                        }}
                        className="enviarButton"
                        id="enviarButton"
                    >
                        <Icon icon={sendOutlined} color="white" />
                    </Button>
                </InputGroup.Append>
            </InputGroup>
            <AtalhoSection open={openSectionAtalho} listaItens={listaItens} handleAtalhoSection={setOpenSectionAtalho} refInput={referenceComponent} />
        </div>

        <ModalDommus titulo="Anotações" open={openModalAnotacoes} close={() => setOpenModalAnotacoes(false)}>
            <Anotacoes atendimento={atendimento} handleUpdateListaAnotacoes={handleUpdateListaAnotacoes} />
        </ModalDommus>
        <ModalDommus titulo="Modelos de mensagens" open={openModalTemplates} close={() => setOpenModalTemplates(false)}>
            <ModalTemplate tipo={templateMensagens} enviarMensagem={receberMensagemInicial} recebeMensagemInput={recebeMensagemInput} atendimento={atendimento} closeModal={() => setOpenModalTemplates(false)} />
        </ModalDommus>
        <ModalDommus titulo="Imagens & Anexos" size="lg" open={openModalUpload} close={() => { setOpenModalUpload(false); }}>
          <Container fluid>
            <Tab.Container defaultActiveKey="direto">
              <Row>
                <Col xs={12} md={12}>
                  <Nav fill variant="tabs">
                    <Nav.Item>
                      <Nav.Link eventKey="direto">{(isBrowser && "Envio ") || ""}Direto</Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link eventKey="imagens">{(isBrowser && "Galeria de ") || ""}Imagens</Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link eventKey="anexos">{(isBrowser && "Galeria de ") || ""}Arquivos</Nav.Link>
                    </Nav.Item>
                  </Nav>
                </Col>
                <Col xs={12} md={12}>
                  <Tab.Content>
                      <Tab.Pane eventKey="direto">
                          <div className='px-3 py-3 border rounded'>
                              <Upload
                                  setMensagemInput={setMensagemInput}
                                  recebeArquivo={recebeArquivo}
                                  setLegendaInput={setLegendaInput}
                                  enviarImagem={enviarImagem}
                                  setOpenModalUpload={setOpenModalUpload}
                              />
                          </div>
                      </Tab.Pane>
                      <Tab.Pane eventKey="imagens">
                          <BrowserView className='px-3 py-3 border rounded'>
                              <Galeria tipo="imagem" definirEstadoModal={setOpenModalUpload} enviarArquivos={enviarImagem}/>
                          </BrowserView>
                          <MobileView>
                              <Galeria tipo="imagem" definirEstadoModal={setOpenModalUpload} enviarArquivos={enviarImagem}/>
                          </MobileView>
                      </Tab.Pane>
                      <Tab.Pane eventKey="anexos">
                          <BrowserView className='px-3 py-3 border rounded'>
                              <Galeria tipo="anexo" definirEstadoModal={setOpenModalUpload} enviarArquivos={enviarImagem}/>
                          </BrowserView>
                          <MobileView>
                              <Galeria tipo="anexo" definirEstadoModal={setOpenModalUpload} enviarArquivos={enviarImagem}/>
                          </MobileView>
                      </Tab.Pane>
                  </Tab.Content>
                </Col>
              </Row>
            </Tab.Container>
          </Container>
        </ModalDommus>
    </>)
}
