import React from "react";
import DommusCharts from "../DommusCharts";
import { DommusAlertaAmarelo } from "../DommusAlertaAmarelo";

export function GraficoDashboardDinamico({ grafico, filtrosGlobais, index=null }) { 
	function montarGrafico() {
		switch(grafico.tipo_grafico) {
			case 'colunas-linhas':
				return <DommusCharts.GraficoColunasComLinhas key={index} chave={grafico.referencia_chave_grafico} titulo={grafico.titulo} filtrosGlobais={filtrosGlobais} height={grafico?.height}/>
			case 'colunas':
				return <DommusCharts.GraficoColunas key={index} chave={grafico.referencia_chave_grafico} titulo={grafico.titulo} filtrosGlobais={filtrosGlobais} height={grafico?.height}/>
			case 'cartao':
				return <DommusCharts.Cartao key={index} chave={grafico.referencia_chave_grafico} titulo={grafico.titulo} filtrosGlobais={filtrosGlobais} height={grafico?.height}/>
			case 'tabela':
				return <DommusCharts.Tabela key={index} chave={grafico.referencia_chave_grafico} titulo={grafico.titulo} filtrosGlobais={filtrosGlobais} height={grafico?.height}/>
			case 'multiplas-tabelas':
				return <DommusCharts.MultiplasTabelas key={index} chave={grafico.referencia_chave_grafico} titulo={grafico.titulo} filtrosGlobais={filtrosGlobais} height={grafico?.height}/>
			case 'linhas':
				return <DommusCharts.Linhas key={index} chave={grafico.referencia_chave_grafico} titulo={grafico.titulo} filtrosGlobais={filtrosGlobais} height={grafico?.height}/>
			case 'setores':
				return <DommusCharts.PieChart key={index} chave={grafico.referencia_chave_grafico} titulo={grafico.titulo} filtrosGlobais={filtrosGlobais} height={grafico?.height}/>
			case 'barra-stack':
				return <DommusCharts.BarStackChart key={index} chave={grafico.referencia_chave_grafico} titulo={grafico.titulo} filtrosGlobais={filtrosGlobais} height={grafico?.height}/>
			default:
				return <DommusAlertaAmarelo key={index} mensagem="Gráfico não existente" classe="text-center"/>
		}
	}

    return montarGrafico()
}
