import React, { useContext, useRef, useState } from "react"
import Popover from "react-bootstrap/Popover"
import Button from "react-bootstrap/Button"
import { Form, Overlay, Row } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlusCircle } from "@fortawesome/free-solid-svg-icons";
import ReactTooltip from "react-tooltip";
import { TipoAtendimentoContext } from "../../contexts/TipoAtendimentoContext";
import { SituacaoAtendimentoContext } from "../../contexts/SituacaoAtendimentoContext"
import { DashboardService } from "../../services/DashboardService";
import { trackPromise } from "react-promise-tracker";
import Swal from "sweetalert2";
import { EmpreendimentoContext } from "../../contexts/EmpreendimentosContext";
import './index.css'
import formatDommus from "../../helpers/format";

export function PopoverAtendenteDashboard({ idUsuario }){ 
    const [show, setShow] = useState(false);
    const [dadosUsuario, setDadosUsuario] = useState(null)
    const target = useRef(null);
    const { tiposAtendimento } = useContext(TipoAtendimentoContext)
    const { situacoesAtendimento } = useContext(SituacaoAtendimentoContext)
    const { empreendimentoLista } = useContext(EmpreendimentoContext)
    
    function buscarQuantidadeAtendimentosUsuario() {
        if(!show) {
            trackPromise(DashboardService.buscarDadosPorUsuario(idUsuario))
                .then(resposta => {
                    setDadosUsuario(resposta.data)
                    setShow(!show)
                }).catch(erro => {
                    console.log(erro)
                    Swal.fire('Ooops...', 'Houve um erro ao buscar os dados do usuário.', 'error')
                })
        } else {
            setShow(!show)
        }
    }

    function montarSecaoTransferencias() {
        let secoesTransferencia = []
        for(let [meioComunicacao, usuario] of Object.entries(dadosUsuario.transferencias)) {
            secoesTransferencia.push(<>
                <Popover.Title as="h3" className="bg-coluna-3-dashboard"><strong>Transferências de Atendimento ({meioComunicacao})</strong></Popover.Title>
                <Popover.Content>
                   {usuario && Array.isArray(usuario) && usuario.length > 0 && 

                    <>
                    {usuario.map((transferencia) => (
                        <Row key={`usuario-${idUsuario}-transferencia-${meioComunicacao}`} className="justify-content-between px-3">
                            <Form.Label>{transferencia.nome ? formatDommus.transformarParaTitulo(transferencia.nome) : 'Impossível resolver'}</Form.Label>
                            <Form.Label><strong>{transferencia.quantidade}</strong></Form.Label>
                        </Row>
                    ))}
                    </>
                   
                   }
                    
                </Popover.Content>
            </>)
        }
        return secoesTransferencia
    }
    
    return (
        <>
            <ReactTooltip effect="solid"/>
            <Button className="float-right btn-mais-info-dashboard btn-sm" data-tip="Mais Informações" ref={target} onClick={() => buscarQuantidadeAtendimentosUsuario()}>
                <FontAwesomeIcon icon={faPlusCircle} color="#fff"/>
            </Button>
            <Overlay target={target.current} show={show}>
                {(props) => (
                <Popover id="popover-basic" {...props} className="w-100 popover-atendente">
                    <Popover.Title as="h3" className="bg-coluna-3-dashboard"><strong>Atendimentos Por Status</strong></Popover.Title>
                    <Popover.Content>
                        {situacoesAtendimento.map(situacao => (
                            <Row key={situacao.id} className="justify-content-between px-3">
                                <Form.Label>{situacao.nome}</Form.Label>
                                <Form.Label><strong>{dadosUsuario.situacoes[situacao.id] || 0}</strong></Form.Label>
                            </Row>
                        ))}
                    </Popover.Content>
                    <Popover.Title as="h3" className="bg-coluna-3-dashboard"><strong>Atendimentos por Tipo</strong></Popover.Title>
                    <Popover.Content>
                        {tiposAtendimento.map(tipoAtendimento => (
                            <Row key={tipoAtendimento.id} className="justify-content-between px-3">
                                <Form.Label>{tipoAtendimento.descricao}</Form.Label>
                                <Form.Label><strong>{dadosUsuario.tiposAtendimentos[tipoAtendimento.id] || 0}</strong></Form.Label>
                            </Row>
                        ))}
                    </Popover.Content>
                    <Popover.Title as="h3" className="bg-coluna-3-dashboard"><strong>Atendimentos por Produto</strong></Popover.Title>
                    <Popover.Content>
                        {empreendimentoLista.map(empreendimento => (
                            <Row key={empreendimento.id} className="justify-content-between px-3">
                                <Form.Label>{empreendimento.nome ? formatDommus.transformarParaTitulo(empreendimento.nome) : 'Impossível resolver'}</Form.Label>
                                <Form.Label><strong>{dadosUsuario.empreendimentos[empreendimento.id] || 0}</strong></Form.Label>
                            </Row>
                        ))}
                    </Popover.Content>
                    {Object.keys(dadosUsuario.transferencias)?.length && montarSecaoTransferencias() || <></>}
                </Popover>
                )}
            </Overlay>
        </>
    );
};