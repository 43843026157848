import React, {Fragment, useEffect, useMemo, useState } from "react";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { PerfilUsuarioService } from "../../services/PerfilUsuarioService";
import { Alert, Col, Container, Form, Image, ListGroup, OverlayTrigger, Row, Tooltip } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import DommusLoading from "../DommusLoading/DommusLoading";
import { FiltrosGlobaisDashboardDinamico } from "../FiltrosGlobaisDashboardDinamico";
import { GraficoDashboardDinamico } from "../GraficoDashboardDinamico";
import { errorToast } from "../DommusToast";
import { faInfoCircle, faTimesCircle } from "@fortawesome/free-solid-svg-icons";
import "./index.css";
import { DommusAlertaAmarelo } from "../DommusAlertaAmarelo";
import caAPI from "../../services/caAPI";

export function AbaDashboard({ 
    configuracoesUsuario, 
    setConfiguracoesUsuario, 
    erroCarregamentoGraficosDisponiveis, 
    setErroCarregamentoGraficosDisponiveis, 
    carregamentoGraficosDisponiveis,
    graficosDisponiveis,
    erroCarregamentoGraficosUsuario,
    setErroCarregamentoGraficosUsuario,
    carregamentoGraficosUsuario,
    bibliotecaGraficosAberta,
    setBibliotecaGraficosAberta,
    idCategoria
}) {
    const [filtroGraficosDisponiveis, setFiltroGraficosDisponiveis] = useState('')
    const [filtrosGlobais, setFiltrosGlobais] = useState({})
    const idUsuario = caAPI.getLocalState('idUsuario')
    const alterarEstiloLista = (movimentando, cor, corBorda) => ({
		background: movimentando && cor || "#eee",
		display: "flex",
		padding: 8,
		minHeight: '500px',
        border: movimentando && `${corBorda} dashed 5px` || 'none',
	});
    const idAreaArrastavelBibliotecaGraficos = `biblioteca-graficos-${idCategoria}`

    function fecharBibliotecaGraficos() {
        setBibliotecaGraficosAberta(false)
    }

    function moverGrafico(evento) {
        let configTelaDashboards = configuracoesUsuario
		if(evento.source?.droppableId != evento.destination?.droppableId) {
			const idGrafico = Number(evento.draggableId?.split('-')[2])
			if(evento.source?.droppableId == idAreaArrastavelBibliotecaGraficos) {
                if(configTelaDashboards?.[idCategoria]) {
                    configTelaDashboards[idCategoria].graficos = [...configuracoesUsuario?.[idCategoria]?.graficos, idGrafico]
                } else {
                    configTelaDashboards[idCategoria] = { graficos: [idGrafico] }
                }
			} else {
                if(configTelaDashboards?.[idCategoria]) {
                    configTelaDashboards[idCategoria].graficos = configuracoesUsuario?.[idCategoria]?.graficos?.filter((grafico) => grafico != idGrafico)
                } else {
                    configTelaDashboards[idCategoria] = { graficos: [] }
                }
			}
		} else if(evento?.source?.droppableId == evento?.destination?.droppableId && 
            Array.isArray(configuracoesUsuario?.[idCategoria]?.graficos) && 
            configuracoesUsuario?.[idCategoria]?.graficos.length
        ) {
            const chaveGrafico = evento.draggableId.split('-')
            if(chaveGrafico[0] != 'item') {
                return
            }
            const idGraficoAlterado = Number(chaveGrafico[2])
            configTelaDashboards[idCategoria].graficos = configuracoesUsuario?.[idCategoria]?.graficos?.filter((grafico) => grafico != idGraficoAlterado)
            configTelaDashboards[idCategoria].graficos.splice(evento.destination.index, 0, idGraficoAlterado)
        }
        setConfiguracoesUsuario(configTelaDashboards)
        if(!idUsuario) {
            errorToast.fire({ text: 'Houve um erro ao tentar atualizar os gráficos. Usuário não encontrado.' })

            return
        }
        PerfilUsuarioService.update(idUsuario, { tela_dashboards: configTelaDashboards })
            .catch(error => {
                console.log(error)
                console.log(error?.response)
                errorToast.fire({ text: 'Houve um erro ao tentar atualizar os gráficos.' })
            })
	}

    function filtroDeGraficosDisponiveis(grafico) {
		return grafico.titulo?.includes(filtroGraficosDisponiveis) && !configuracoesUsuario?.[idCategoria]?.graficos?.includes(grafico.id_grafico)
	}

    function GraficosDisponiveis() {
        const graficosFiltrados = graficosDisponiveis.filter(filtroDeGraficosDisponiveis)

        return graficosFiltrados.length && (
            <Fragment>
                {graficosFiltrados.map((grafico, index) => (
                    <Draggable key={`grafico-disponivel-${grafico.id_grafico}`} draggableId={`grafico-disponivel-${grafico.id_grafico}`}  index={index}>
                        {(provided) => (
                            <div {...provided.draggableProps} {...provided.dragHandleProps} ref={provided.innerRef}  className="preview-grafico-section">
                              <ListGroup.Item className="bloco-grafico-preview">
                                <Image fluid src={grafico.url_imagem || ''} rounded className="imagem-preview-grafico"/>
                                {grafico.titulo && (
                                    <Row className="text-center titulo-grafico">
                                        <Col className="text-center">{grafico.titulo}</Col>
                                    </Row>
                                ) || <></>}
                                {grafico.descricao && (
                                    <Row>
                                        <Col className="text-center descricao-grafico">
                                            <small className="text-center">{grafico.descricao}</small>
                                        </Col>
                                    </Row>
                                ) || <></>}
                              </ListGroup.Item>
                            </div>
                        )}
                    </Draggable>
                ))}
            </Fragment>
        ) || <DommusAlertaAmarelo mensagem="Nenhum gráfico disponível no momento."/> 
    }

    return <DragDropContext onDragEnd={moverGrafico}>
        <Droppable droppableId={idAreaArrastavelBibliotecaGraficos}>
            {(provided, snapshot) => (
                    <div ref={provided.innerRef} className={`biblioteca-graficos ${bibliotecaGraficosAberta ? 'aberta' : ''}`}>
                        <Row>
                          <div className="title-biblioteca-grafico">
                            <span className="title-text">Biblioteca de Gráficos</span>
                            <OverlayTrigger placement="bottom" overlay={<Tooltip id="fechar-biblioteca-graficos">Fechar biblioteca de gráficos</Tooltip>}>
                              <FontAwesomeIcon onClick={fecharBibliotecaGraficos} icon={faTimesCircle} color="var(--danger)" size="lg" style={{ cursor: 'pointer' }}/>
                            </OverlayTrigger>
                          </div>
                        </Row>
                        <Row>
                            <Col className="px-4 mb-3">
                                <Form.Control 
                                    className="pesquisa-grafico" 
                                    type="text" 
                                    placeholder="Pesquisar" 
                                    value={filtroGraficosDisponiveis} 
                                    onChange={(event) => setFiltroGraficosDisponiveis(event.target.value)}
                                />
                            </Col>
                        </Row>
                        <Row>
                          <Col className="px-4 mb-0">
                            <Alert variant="warning" style={{padding: '5px 8px', fontStyle: 'italic', fontSize: '0.9rem', marginBottom: '8px'}}>Arraste e solte os gráficos para personalizar seu painel de insights.</Alert>
                          </Col>
                        </Row>
                        {erroCarregamentoGraficosDisponiveis && 
                            <div onClick={() => setErroCarregamentoGraficosDisponiveis(false)} style={{ cursor: 'pointer' }}>
                                <DommusAlertaAmarelo mensagem="Houve um erro ao tentar carregar os gráficos disponíveis clique aqui para recarregar."/>
                            </div> || 
                            carregamentoGraficosDisponiveis && 
                            <DommusLoading loadWrapperPosition={"relative"} fundo="#eee"/> || 
                            <ListGroup ref={provided.innerRef} {...provided.droppableProps} className="lista-graficos-disponiveis" style={alterarEstiloLista(snapshot.isDraggingOver, 'var(--dommus-color)', '#0c2440')}>
                                <GraficosDisponiveis/>
                            </ListGroup>
                        }
                    </div>
            )}
        </Droppable>
        {erroCarregamentoGraficosUsuario ? (
            <div onClick={() => setErroCarregamentoGraficosUsuario(false)} style={{ cursor: 'pointer' }}>
                <DommusAlertaAmarelo mensagem="Houve um erro ao tentar carregar os gráficos clique aqui para recarregar." classe="text-center"/>
            </div> 
        ) : carregamentoGraficosUsuario ? (
            <Container className="bg-white border rounded px-0" fluid>
                <DommusLoading loadWrapperPosition={"relative"} fundo="#eee" style={{ minHeight: '70vh', zIndex: '1' }}/> 
            </Container>
        ) : true ? 
        <>
          {
            configuracoesUsuario?.[idCategoria]?.filtrosGlobais && (
                  <FiltrosGlobaisDashboardDinamico 
                      configuracaoFiltros={configuracoesUsuario?.[idCategoria]?.filtrosGlobais || []} 
                      atualizarFiltrosGlobais={(filtrosAtualizados) => setFiltrosGlobais(filtrosAtualizados)}
                  />
              ) || 
              <></>
          }
          <Droppable droppableId={`graficos-${idCategoria}`}>
              {(provided, snapshot) => (
                  <Row 
                      className="p-0 m-0" 
                      ref={provided.innerRef} 
                      style={alterarEstiloLista(snapshot.isDraggingOver, '#f2f3f8', '#eee')}
                      {...provided.droppableProps} 
                  >
                      {configuracoesUsuario?.[idCategoria]?.graficos?.length ? 
                        configuracoesUsuario[idCategoria].graficos.map((idGrafico, index) => {
                            const grafico = graficosDisponiveis.find((grafico) => grafico.id_grafico === idGrafico)
                            return grafico && Object.keys(filtrosGlobais).length ? 
                              <Draggable key={`grafico-draggable-${idGrafico}`} draggableId={`item-arrastavel-${idGrafico}`} index={index}>
                                {(provided) => (
                                    <Col lg={grafico.tamanho_coluna || 6} {...provided.draggableProps} {...provided.dragHandleProps} ref={provided.innerRef} className="px-0">
                                        <GraficoDashboardDinamico grafico={grafico} filtrosGlobais={filtrosGlobais} index={index}/>
                                    </Col>
                                )}
                              </Draggable> 
                            : <></>
                        }) : (
                          <Col className="mt-3">
                              <DommusAlertaAmarelo
                                  mensagem="Nenhum gráfico adicionado até o momento. Arraste um grafico aqui para visualizá-lo."
                                  classe="text-center"
                                  icone={faInfoCircle}
                                  variant="info"
                              />
                          </Col>
                        )
                      }
                  </Row>
              )}
          </Droppable>
        </> : <></>}
    </DragDropContext> 
}
