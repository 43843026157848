import { faExchangeAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useMemo, useState } from 'react'
import { Form, FormGroup, InputGroup, OverlayTrigger, Tooltip } from 'react-bootstrap'
import "./index.css";
import { useFormContext } from 'react-hook-form';
import moment from 'moment';

export default function IntervaloDatasAgrupadosGrafico({ campos, configuracao }) {
    const [campoSelecionado, setCampoSelecionado] = useState();
    const [indexCampoSelecionado, setIndexCampoSelecionado] = useState(0);
    const methods = useFormContext();

    const listaAgrupamentoName = useMemo(() => {
        return campos.map((campo) => {
            return {
                label: campo?.descricaoCampo,
                nomeDe: campo?.inicial,
                nomeAte: campo?.final,
                value: campo?.agrupamentoNome
            }
        });
    }, [campos]);
    const datasPeriodo = useMemo(()=>{
      if(configuracao && configuracao?.periodoPadrao && Number(configuracao?.periodoPadrao) > 0){
        return{
          start: moment().subtract(configuracao?.periodoPadrao, "days").format("YYYY-MM-DD"),
          end: moment().format("YYYY-MM-DD")
        }
      }else{
        return null;
      }
    },[configuracao]);

    useEffect(() => {
        if (campos.length) {
            let selecionado = campos.find((campo) => campo?.agrupamentoNome === listaAgrupamentoName[indexCampoSelecionado].value);
            if (selecionado) {
                setCampoSelecionado(selecionado);
            }
        }
    }, [campoSelecionado, listaAgrupamentoName, indexCampoSelecionado])


    const handleChangeCampo = () => {
        let value = 0;
        if (indexCampoSelecionado !== (listaAgrupamentoName.length - 1)) {
            value = indexCampoSelecionado + 1;
        }

        listaAgrupamentoName.forEach((campo) => {
            methods.reset({
                [campo.nomeAte]: datasPeriodo?.end ?? "",
                [campo.nomeDe]: datasPeriodo?.start ?? "",
            })
        })
        setIndexCampoSelecionado(value);
    }

    const resolveCampo = (campo, index) => {
        let valueSelecionado = listaAgrupamentoName[indexCampoSelecionado]?.value;
        return (
            <div key={index} hidden={valueSelecionado !== campo.agrupamentoNome} className='w-90'>
                <div className='d-flex'>
                    <Form.Control
                        className="spaceData"
                        type="date"
                        {...methods.register(campo?.inicial)}
                        defaultValue={datasPeriodo?.start ?? ""}
                    />
                    <Form.Control
                        className="spaceData"
                        type="date"
                        {...methods.register(campo?.final)}
                        defaultValue={datasPeriodo?.end ?? ""}
                    />
                </div>
            </div>
        )
    }

    return (
        <FormGroup className='agrupamento-section-grafico'>
            <Form.Label className='label-formulario'>{campoSelecionado?.descricaoCampo ?? ''}</Form.Label>
            <InputGroup>
                {campos.length ? campos.map((campo, index) => resolveCampo(campo, index)) : <></>}
                <InputGroup.Append className='btn-alterar-campo-grafico'>
                    <OverlayTrigger
                        placement="top"
                        overlay={
                            <Tooltip id="btn-assinar">{`Alterar para ${indexCampoSelecionado === (listaAgrupamentoName.length - 1) ? listaAgrupamentoName[0].label : listaAgrupamentoName[indexCampoSelecionado + 1].label}`}</Tooltip>
                        }
                    >
                        <InputGroup.Text className='btn-alterar-campo-grafico'
                            onClick={() => handleChangeCampo()}
                        >
                            <FontAwesomeIcon icon={faExchangeAlt} />
                        </InputGroup.Text>
                    </OverlayTrigger>
                </InputGroup.Append>
            </InputGroup>
        </FormGroup>
    )
}
