import React, { useContext, useEffect, useState, useRef } from 'react';
import './style.scss';
import  DommusLoading  from '../../../../components/DommusLoading/DommusLoading';
import { AtendimentoContext } from '../../../../contexts/AtendimentoContext';
import AnotacoesPresencial from '../../../../components/AnotacoesPresencial';

export default function PresencialComponent() {
    const [loading, setLoading] = useState(false);
    const { atendimentoAtivo } = useContext(AtendimentoContext);

    return (
        (loading && <DommusLoading />) ||  <div className="presencial">
          <AnotacoesPresencial atendimento={atendimentoAtivo} showAtendimentosDoDia={true} />
        </div >
    );
} 
