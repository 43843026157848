import React, { useState, useEffect, useContext, useMemo, useRef, useCallback } from 'react';
import { Card,  Form, Button, Row, Col, Alert } from "react-bootstrap"
import caAPI from "../../../../services/caAPI";
import { trackPromise } from "react-promise-tracker";
import Swal from "sweetalert2";
import { Icon } from '@iconify/react';
import sendOutlined from '@iconify-icons/ant-design/send-outlined';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEraser, faSearch } from "@fortawesome/free-solid-svg-icons";
import './style.scss';
import { AtendimentoContext } from '../../../../contexts/AtendimentoContext';
import { templatesFilter } from '../../../../helpers/TemplateFilter';
import {useForm} from "react-hook-form";

export default function ModalTemplate({ tipo, enviarMensagem, recebeMensagemInput, closeModal }) {
    const {atendimentoAtivo} = useContext(AtendimentoContext);
    const [listaTemplates, setListaTemplates] = useState([]);
    const [mensagemInput, setMensagemInput] = useState('');
    const {register, handleSubmit, setValue} = useForm();
    const listaFixa = useRef(null);

    function enviaTemplate() {
        enviarMensagem(mensagemInput);
        closeModal();
    }

    useEffect(() => {
        recebeMensagemInput(mensagemInput)
    }, [recebeMensagemInput, mensagemInput]);

    function selecionaMensagem(id_template) {
        listaTemplates.forEach(template => {
            if (template.id.toString() === id_template) {
                setMensagemInput({
                    id_template:id_template,
                    mensagem: template.mensagem.replace(/(<([^>]+)>)/ig, '')
                })
            }
        });
    }

    useEffect(() => {
      let parametros = [tipo]; 
      let metodo = caAPI.meio_comunicacao.getTemplate;
      if(tipo === 'whatsapp'){
        metodo = caAPI.meio_comunicacao.getTemplatesAprovadosComOrigem;
        parametros.push(atendimentoAtivo.origem.id_contato_origem)
      }
      trackPromise(
        metodo(...parametros).then(res => {
            listaFixa.current = templatesFilter(res.data, atendimentoAtivo);
            setListaTemplates(listaFixa.current);
        }).catch(err => {
            Swal.fire({
                titleText: "Erro",
                text: "Houve um problema ao buscar os meios de comunicação  " + err,
                icon: 'error'
            });
        }),
      )
    }, [tipo])

    const onSubmitFilter = (data) =>{
        if(data.nameTemplate && data.nameTemplate){
            let tempList = listaFixa.current.filter((element)=>element.nome.toLowerCase().includes(data.nameTemplate.toLowerCase()));
            tempList = tempList.filter((element)=>element.mensagem.toLowerCase().includes(data.contentTemplate.toLowerCase()));
            setListaTemplates(tempList);
        }else if(data.nameTemplate){
            setListaTemplates(listaFixa.current.filter((element)=>element.nome.toLowerCase().includes(data.nameTemplate.toLowerCase())));
        }
        else if(data.contentTemplate){
            setListaTemplates(listaFixa.current.filter((element)=>element.mensagem.toLowerCase().includes(data.contentTemplate.toLowerCase())));
        }
        else{
            setListaTemplates(listaFixa.current)
        }
    }

    const cleanFilter = ()=>{
        setValue('nameTemplate', '');
        setValue('contentTemplate', '');
        setListaTemplates(listaFixa.current);

    };

    return <>
        <div className="whatsapp-template">
            <Form className='filterForm' onSubmit={handleSubmit(onSubmitFilter)}>
                <Form.Group>
                    <Row className='align-items-end'>
                        <Col md={4} xs={12}>
                            <Form.Label>Nome Template: </Form.Label>
                            <Form.Control defaultValue='' type="text" placeholder="Nome do template..." {...register('nameTemplate')}/>
                        </Col>
                        <Col md={5} xs={12}>
                            <Form.Label>Conteúdo da mensagem: </Form.Label>
                            <Form.Control defaultValue='' type="text" placeholder="Conteúdo da mensagem..." {...register('contentTemplate')}/>
                        </Col>
                        <Col md={3} xs={12} className='btn-group btn-group-pesquisa'>
                            <Button variant="primary" type="submit" className='btnFilter'>
                                <FontAwesomeIcon icon={faSearch}/>
                                <span>Procurar</span>
                            </Button>
                            <Button variant="secondary" className='btnFilter' onClick={()=>cleanFilter()}>
                                <FontAwesomeIcon icon={faEraser}/>
                                <span>Limpar</span>
                            </Button>
                        </Col>
                    </Row>
                </Form.Group>
            </Form>
            <Form className="listaTemplates">
                <Form.Group style={{ margin: "1rem 1rem 0 1rem" }}>
                    <Form.Control
                        as="radio"
                        custom
                        onChange={(event) => selecionaMensagem(event.target.id)}
                    >
                        {listaTemplates.length ? listaTemplates.map((template) =>
                            <div key={template.id}>
                                <Card className="templateCard">
                                    <div className="nomeTemplate">
                                        {template.nome}
                                    </div>
                                    <Form.Check
                                        className="template"
                                        type="radio"
                                        id={template.id}
                                        value={template.id}
                                    >
                                        <Form.Check.Input type='radio' name="selecionatemplate" isValid />
                                        <Form.Label>
                                            <div >
                                                {template.mensagem.replace(/(<([^>]+)>)/ig, '')}
                                            </div>
                                        </Form.Label>
                                    </Form.Check>
                                </Card>
                            </div>
                        ) :
                        <Alert variant='info'>Não foi encontrado nenhum template!</Alert>
                        }

                    </Form.Control>
                </Form.Group>
            </Form>
        </div>
        <div className="footerEnviarMensagem">
            <Button className="btnEnviaPrimeiraMensagem" onClick={() => {
                mensagemInput
                    ?
                    enviaTemplate()
                    :
                    Swal.fire({
                        titleText: "Atenção",
                        text: "Selecione uma mensagem para enviar",
                        icon: 'warning'
                    })
            }}>
                Enviar mensagem selecionada <Icon icon={sendOutlined} color="white" />
            </Button>
        </div>
    </>;
}
