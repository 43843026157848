import React, { useState } from "react";
import "../MenuLateral/index.css";
import {
  faStream,
  faPowerOff,
  faCog,
  faArrowLeft,
  faTicketAlt,
  faCommentAlt,
  faHeadphonesAlt,
  faEnvelope,
  faHeadset,
  faPeopleArrows,
  faLayerGroup,
  faTag,
  faArrowsAltH,
  faClipboardList,
  faChartLine,
  faClock,
  faBarsProgress,
  faUser,
  faPaperPlane
} from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";
import ReactTooltip from "react-tooltip";
import {
  faWhatsapp,
  faFacebookSquare,
  faLinkedin,
  faChrome,
  faWindows,
  faLinux,
} from "@fortawesome/free-brands-svg-icons";
import roundDashboard from '@iconify-icons/ic/round-dashboard';
import caAPI from "../../services/caAPI";
import { Icon } from "@iconify/react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { isMobile } from 'react-device-detect';

export default function MenuTopo(props) {
  const [open, setOpen] = useState(props.open);
  const [openConfiguracoes, setOpenConfiguracoes] = useState(
    props.openConfiguracoes
  );
  const [openAtendimento, setOpenAtendimento] = useState(
    props.openAtendimento ?? false
  );
  const [openRelatorios, setOpenRelatorios] = useState(false);

  function mudarEstado(e) {
    e.preventDefault();
    setOpen(!open);
  }

  function abrirConfiguracoes(e) {
    e.preventDefault();
    setOpenConfiguracoes(!openConfiguracoes);
    setOpenRelatorios(false);
  }

  function abrirAtendimento(e) {
    e.preventDefault();
    setOpenAtendimento(!openAtendimento);
  }

  function abrirRelatorios(e) {
    e.preventDefault();
    setOpenRelatorios(!openRelatorios);
    setOpenConfiguracoes(false);
  }

  function logout(e) {
    e.preventDefault();
    caAPI.logout();
    window.location.reload(false);
  }

  const classeAsideSidebar = "sidebar" + (open ? "" : " hide");
  const classeSidebarWrapper = "sidebar-wrapper" + (open ? "" : " is-closed");
  const classeAtendimento =
    "suporte is-menu " + (openAtendimento ? " open" : "");
  const classeConfiguracoes =
    "suporte is-menu " + (openConfiguracoes ? " open" : "");
  const classeRelatorios =
    "suporte is-menu " + (openRelatorios ? " open": "");
  const telas = caAPI.getLocalState("telas");

  return (
    <>
      <div
        className={"sidenav-overlay " + (open && "open")}
        onClick={() => {
          setOpen(false);
        }}
      >
        &nbsp;
      </div>
      <aside className={classeAsideSidebar} id="asideMobile">
        {!open ? <ReactTooltip /> : <></>}
        <div className={classeSidebarWrapper} id="asideWrapperMobile">
          <div className="sidebar-title">
            <span>Menu</span>
          {!isMobile &&  <div style={{ cursor: "pointer", color: '#0000EE' }} data-tip="Menu" onClick={mudarEstado}>
              <FontAwesomeIcon icon={faStream} />
            </div>}
          </div>
          <div className="siderbar-holder">
            <div className="menu-structure">
              <ul>
                {telas.includes("dashboards") && (
                  <li data-tip="Dommus Insights">
                    <Link to='/dashboards'>
                      <Icon icon={roundDashboard} color='#f44336' width="22px" data-tip="Dommus Insights"/>
                      <p>Dommus Insights</p>
                    </Link>
                  </li>
                )}
                {telas.includes("portal_cliente") && (
                  <li>
                    <OverlayTrigger
                      key="buttonMenuArquivo"
                      placement="right"
                      overlay={
                        <Tooltip id={`tooltip-editar`}>Atendimento</Tooltip>
                      }
                    >
                      <Link to="/">
                        <FontAwesomeIcon color="#F44336" icon={faHeadset} />
                        <p>Atendimento</p>
                      </Link>
                    </OverlayTrigger>
                  </li>
                )}
                {telas.includes("arquivo") && (
                  <li>
                    <OverlayTrigger
                      key="buttonMenuArquivo"
                      placement="right"
                      overlay={<Tooltip id={`tooltip-editar`}>Lixeira</Tooltip>}
                    >
                      <Link to="/arquivo">
                        <Icon icon="formkit:trash" color="#353252" />
                        <p>Lixeira</p>
                      </Link>
                    </OverlayTrigger>
                  </li>
                )}
                {telas.includes("dashboard") && (
                  <li>
                    <OverlayTrigger placement="right" overlay={<Tooltip>Acompanhamento de Atendentes - Tempo Real</Tooltip>}>
                      <Link to="/dashboard">
                        <FontAwesomeIcon icon={faBarsProgress}  color="#4caf50"/><p>Acompanhamento de Atendentes - Tempo Real</p>
                      </Link>
                    </OverlayTrigger>
                  </li>
                )}
                {telas.includes("disparoEmMassa") && (
                  <li>
                    <OverlayTrigger placement="right" overlay={<Tooltip>Disparo em Massa</Tooltip>}>
                      <Link to="/disparo-massa">
                        <FontAwesomeIcon icon={faPaperPlane} /><p>Disparo em Massa</p>
                      </Link>
                    </OverlayTrigger>
                  </li>
                )}
                {telas.includes("portal_cliente") && (
                  <li>
                    <OverlayTrigger
                      key="buttonMenuArquivo"
                      placement="right"
                      overlay={
                        <Tooltip id={`tooltip-editar`}>
                          Fila de atendimento
                        </Tooltip>
                      }
                    >
                      <Link to="/filas-atendimento">
                        <FontAwesomeIcon
                          icon={faPeopleArrows}
                          color="#bdd62f"
                        />
                        <p>Fila de atendimento</p>
                      </Link>
                    </OverlayTrigger>
                  </li>
                )}
                {telas.includes("quadro_historico") && (
                  <li>
                    <OverlayTrigger
                      key="buttonMenuArquivo"
                      placement="right"
                      overlay={
                        <Tooltip id={`tooltip-editar`}>
                          Acompanhamento de Atendimentos
                        </Tooltip>
                      }
                    >
                      <Link to="/quadro-historico">
                        <FontAwesomeIcon
                          icon={faClipboardList}
                          color="#2fd6d3"
                        />
                        <p>Acompanhamento de Atendimentos</p>
                      </Link>
                    </OverlayTrigger>
                  </li>
                )}
              </ul>
            </div>
            <div className="info-structure">
              <ul>
                {/* <li onClick={abrirRelatorios}>
                  <a href="#">
                    <FontAwesomeIcon
                      icon={faTh}
                      color="#868AA8"
                      data-tip="Relatórios"
                    />
                    <p>Relatórios</p>
                  </a>
                </li> */}
                <li onClick={abrirConfiguracoes}>
                  <a href="#">
                    <FontAwesomeIcon
                      icon={faCog}
                      color="#f7941d"
                      data-tip="Configurações"
                    />
                    <p>Configurações</p>
                  </a>
                </li>
                <li onClick={logout}>
                  <Link to="/sair">
                    <FontAwesomeIcon
                      icon={faPowerOff}
                      color="#f44336"
                      data-tip="Sair"
                    />
                    <p>Sair</p>
                  </Link>
                </li>
              </ul>
              <div className="copy">
                Copyright © 2020{" "}
                <a href="https://www.linkedin.com/company/dommus-sistemas/">
                  Dommus Sistemas
                </a>
              </div>
            </div>
          </div>
        </div>
      </aside>
      <div className="menu-sections">
        {/* Atendimento */}
        <section className={classeAtendimento}>
          <header>
            Atendimento ao Usuário
            <div className="close-pane" onClick={abrirAtendimento}>
              <FontAwesomeIcon icon={faArrowLeft} color="#868aa8" />
            </div>
          </header>
          <h6>Suporte:</h6>
          <ul className="chat">
            <li>
              <a
                href="https://g3comunicacao.tomticket.com/?account=1390400P23042018093406"
                target="_blank" rel="noreferrer"
              >
                <FontAwesomeIcon icon={faTicketAlt} color="#ffcd56" />
                <p>Ticket</p>
              </a>
              <span>|</span>
              <a
                href="https://g3comunicacao.tomticket.com/chat/?id=EP21725&amp;ac=1390400P23042018093406"
                target="_blank" rel="noreferrer"
              >
                <FontAwesomeIcon icon={faCommentAlt} color="#ffcd56" />
                <p>Chat</p>
              </a>
            </li>
          </ul>
          <h6>Telefones:</h6>
          <ul className="telefones">
            <li>
              <a href="tel:+55 31 38899776">
                <FontAwesomeIcon icon={faHeadphonesAlt} color="#673AB7" />
                <p>31 3889 - 9776</p>
              </a>
            </li>
            <li>
              <a href="tel:+55 31 984895755">
                <FontAwesomeIcon icon={faWhatsapp} color="#009688" />
                <p>31 98489 - 5755</p>
              </a>
            </li>
          </ul>
          <h6>Email:</h6>
          <ul className="email">
            <li>
              <a href="mailto:comercial@dommus.com.br">
                <FontAwesomeIcon icon={faEnvelope} color="#E57373" />
                <p>comercial@dommus.com.br</p>
              </a>
            </li>
          </ul>
          <h6>Redes Sociais:</h6>
          <ul className="redes-sociais">
            <li>
              <a
                href="https://www.facebook.com/dommussistemas/"
                target="_blank" rel="noreferrer"
              >
                <FontAwesomeIcon icon={faFacebookSquare} color="#3b5998" />
                <p>Facebook</p>
              </a>
              <span>|</span>
              <a
                href="https://www.linkedin.com/company/dommus-sistemas/"
                target="_blank" rel="noreferrer"
              >
                <FontAwesomeIcon icon={faLinkedin} color="#3b5998" />
                <p>LinkedIn</p>
              </a>
            </li>
          </ul>
          <h6>Site:</h6>
          <ul className="website">
            <li>
              <a href="https://website.dommus.com.br" target="_blank" rel="noreferrer">
                <FontAwesomeIcon icon={faChrome} color="#f7941d" />
                <p>Website</p>
              </a>
            </li>
          </ul>
          <h6>V 0.0.1</h6>
        </section>
        {/* Relatórios */}
        <section className={classeRelatorios}>
          <header>
            Relatórios
            <div className="close-pane" onClick={abrirRelatorios}>
              <FontAwesomeIcon icon={faArrowLeft} color="#868aa8" />
            </div>
          </header>
          <ul className="chat">
            {/* <li onClick={abrirRelatorios}>
              <Link to="/relatorios/filtro_interacoes">
                <p>
                  <FontAwesomeIcon icon={faFilter} color="#F06292"/>Filtro de Interações
                </p>
              </Link>
            </li>
            <li onClick={abrirRelatorios}>
              <Link to="/relatorios/filtro_notificacoes">
                <p><FontAwesomeIcon icon={faFilter} color="#FFC107"/>Filtro de Notificações</p>
              </Link>
            </li>            */}
            <li onClick={abrirRelatorios}>
              <Link to="/relatorios/relatorios_diversos">
                <p><FontAwesomeIcon icon={faChartLine} color="#F44336"/>Relatórios Diversos</p>
              </Link>
            </li>
          </ul>
        </section>
        {/* Configurações */}
        <section className={classeConfiguracoes}>
          <header>
            Configurações
            <div className="close-pane" onClick={abrirConfiguracoes}>
              <FontAwesomeIcon icon={faArrowLeft} color="#868aa8" />
            </div>
          </header>
          <ul className="chat">
            <li onClick={abrirConfiguracoes}>
              <Link to="/tempo-online">
                <p><FontAwesomeIcon icon={faClock} color="#183153"/>Acompanhamento de Tempo Online</p>
              </Link>
            </li>
            <li>
              <OverlayTrigger key="buttonMenuArquivo" placement="right" overlay={ <Tooltip id={`tooltip-editar`}> Aplicação para Linux </Tooltip> } >
                <a href={process.env.PUBLIC_URL + '/Desktop/DommusConectaLinux.zip'} target="_blank">
                  <FontAwesomeIcon icon={faLinux} color="#000"/>
                  <p>Baixar aplicativo para Linux</p>
                </a>
              </OverlayTrigger>
            </li>
            <li>
              <OverlayTrigger key="buttonMenuArquivo" placement="right" overlay={ <Tooltip id={`tooltip-editar`}> Aplicação para Windows </Tooltip> } >
                <a href={process.env.PUBLIC_URL + '/Desktop/DommusConectaWindows.zip'} target="_blank">
                  <FontAwesomeIcon icon={faWindows} color="#0379D9"/>
                  <p>Baixar aplicativo para Windows</p>
                </a>
              </OverlayTrigger>
            </li>
            <li onClick={abrirConfiguracoes}>
              <Link to="/integracoes">
                <p><FontAwesomeIcon icon={faArrowsAltH} color="#916BBF"/>Integrações</p>
              </Link>
            </li>
            <li onClick={abrirConfiguracoes}>
              <Link to="/configuracao/label">
                <p><FontAwesomeIcon icon={faTag} color="#BDD62F"/>Label</p>
              </Link>
            </li>
            <li onClick={abrirConfiguracoes}>
              <Link to="/configuracao/perfil">
                <p><FontAwesomeIcon icon={faUser} color="#4caf50"/>Meu Perfil</p>
              </Link>
            </li>
            <li onClick={abrirConfiguracoes}>
              <Link to="/configuracao/contatos_notificacao_historico">
                <p>
                  <FontAwesomeIcon icon={faPaperPlane} color="#FD7E14"/>Contatos para Notificação Histórico
                </p>
              </Link>
            </li>
            <li onClick={abrirConfiguracoes}>
              <Link to="/configuracao/template">
                <p>
                  <FontAwesomeIcon icon={faLayerGroup} color="#F55B4F"/>Templates
                </p>
              </Link>
            </li>
          </ul>
        </section>
      </div>
    </>
  );
}
