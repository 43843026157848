import React, { createContext, useState, useEffect} from "react";
import Swal from "sweetalert2";
import LeadsService from "../services/LeadsService";

export const EmpreendimentoContext = createContext({});

export function EmpreendimentoProvider({ children }) {
  const [empreendimentoLista, setEmpreendimentoLista] = useState([]);
  const [empreendimentosVendasList, setEmpreendimentosVendasList] = useState([]); 
  const [unidadesEmpreendimento, setUnidadesEmpreendimento] = useState([]); 
  const [mapaEmpreendimentos, setMapaEmpreendimento] = useState(new Map());

  useEffect(()=>{
    const novoMapa = new Map();
    empreendimentoLista.forEach((tag) => {
      novoMapa.set(Number(tag.id), tag);
    });
    setMapaEmpreendimento(novoMapa);
  }, [empreendimentoLista]);

  const recarregarEmpreendimentos = ()=>{
    LeadsService.getEmpreendimentos()
        .then(res => {
        setEmpreendimentoLista(res.data ? res.data : []);
    }).catch(err => Swal.fire({
        titleText: "Erro",
        text: "Houve um problema ao buscar as empreendimentos  " + err,
        icon: 'error'
    }))
  };

  const resolverNomeEmpreendimento = (idEmpreendimento, padrao = "Não Atribuído") => {
    let empreendimento = mapaEmpreendimentos.get(Number(idEmpreendimento));
    return (empreendimento && empreendimento.nome) || padrao;
  };

  return (
    <EmpreendimentoContext.Provider value={{
      empreendimentoLista,
      recarregarEmpreendimentos,
      resolverNomeEmpreendimento,
      empreendimentosVendasList,
      setEmpreendimentosVendasList,
      unidadesEmpreendimento, 
      setUnidadesEmpreendimento
    }}>
      {children}
    </EmpreendimentoContext.Provider>
  )
}
